import React from 'react';
import {Link} from 'react-router-dom';
import Login from './login';
import About from './aboutus';
import Contact from './contactus';

const Header=()=>{
return(
   <div className='sticky-top' >
<div className='d-inline'>
  <div className='row1'>
<div className='col-lg-3 m-0 p-0 ' >

  <img className="ms-0" src="COEQUAL_NIDHI.jpg" alt='coequal-nidhi' height="90px" width="300px">
  </img>
  

</div>
<div className='col-lg-8 ms-0 mt-5 pt-3'>

  <nav class="navbar w-100 navbar-expand-lg" >
  <div class="container-fluid">
  <button class="navbar-toggler text-center" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" href="#"></a>
   
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 ms-5">
        <li className="nav-item">
          <a className="nav-link  active text-dark a ms-2 ps-4" aria-current="page" href="/"><strong className='navlink'>HOME</strong></a>
        </li>
        <li class="nav-item">
        <Link to="/aboutus" class=" nav-link ms-4 text-dark text-decoration-none a" ><b>ABOUT&nbsp;US</b></Link>
         
        </li>

        
        {/*<li class="nav-item ms-4  mt-1">
          <Link class="nav-link text-white a" to="project"><b>OUR PLANS</b></Link>
</li> */}


        <li class="nav-item dropdown ms-4">
          <a class="nav-link a" href="#" id="navbarDarkDropdownMenuLink"  role="button"
          aria-expanded="false">
           <b className='text-dark '>OUR SERVICES</b>
          </a>
          <ul class="dropdown-menu dropdown-content ms-2" aria-labelledby="navbarDarkDropdownMenuLink"
           style={{backgroundColor:'white'}}>
            <li><Link class="dropdown-item a" to="/fixeddeposite" >Fixed Deposit(FD)</Link></li>
            <li><Link class="dropdown-item text-dark" to="/recurringdp">Recurring Deposit(RD)</Link></li>
            <li><Link class="dropdown-item  text-dark" to="/roi">Return On Investment(ROI)</Link></li>
            <li><Link class="dropdown-item  text-dark" to="/kamadenu">Kamadhenu Daily Deposit Scheme</Link></li>
            <li><Link class="dropdown-item  text-dark" to="/kalpa">Kalpavruksha Scheme</Link></li>
          </ul>
        </li>
     


<li class="nav-item ms-4">
          <Link class="nav-link text-dark a" to="/member"><b>MEMBERSHIP</b></Link>
        </li>
        
<li class="nav-item ms-4  ">
          <Link class="nav-link text-dark a" to="/contactus"><b>CONTACT&nbsp;US</b></Link>
        </li>

     {/*} <li className=' me-2 ms-2'>
      <form className="form-inline me-5">
  {/* <a href="#" className=" btn  btn-sm bg-danger text-white me-1"><i className="fa fa-user"></i> <b className='fs-6'>MEMBER</b></a>  
  <Link to="/login" className="nav-link btn nav-item btn-sm  me-2 text-dark"><b>LOGIN</b></Link>

      </form>
      </li> 
      */}
      </ul>
     {/* <form class="d-flex">
     <div className='d-inline-flex align-items-center' style={{height:45}}>
      <a className='btn btn-sm btn-outline-dark btn-sm-square rouded-circle me-2' href="https://twitter.com/CoequalServices">
        <i className='fab fa-twitter fw-normal'></i>
      </a>
     </div>
     <div className='d-inline-flex align-items-center' style={{height:45}}>
      <a className='btn btn-sm btn-outline-dark btn-sm-square rouded-circle me-2' href="">
        <i className='fab fa-facebook-f fw-normal'></i>
      </a>
     </div>
     <div className='d-inline-flex align-items-center' style={{height:45}}>
      <a className='btn btn-sm btn-outline-dark btn-sm-square rouded-circle me-2' href="https://www.linkedin.com/feed/?trk=sem-ga_campid.14650114788_asid.127961666300_crid.651859540485_kw.linkedin%20login_d.c_tid.kwd-12704335873_n.g_mt.e_geo.9062033">
        <i className='fab fa-linkedin-in fw-normal'></i>
      </a>
     </div>
     <div className='d-inline-flex align-items-center' style={{height:45}}>
      <a className='btn btn-sm btn-outline-dark btn-sm-square rouded-circle me-2' href="https://www.instagram.com/jayanthinathan_prabhakaran/">
        <i className='fab fa-instagram fw-normal'></i>
      </a>
     </div>
     <div className='d-inline-flex align-items-center' style={{height:45}}>
      <a className='btn btn-sm btn-outline-dark btn-sm-square rouded-circle me-2' href="https://www.youtube.com/@coequalservices">
        <i className='fab fa-youtube fw-normal'></i>
      </a>
     </div>
    </form> */}
    </div>
  </div>
</nav>
</div>
  </div>
 
</div>
    </div>
)
};
export default Header;