import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import './features.css';

const features = [
	{
		title: "SME's",
		description:
			"It accepts public and corporate deposits and offers a variety of financial services products to its customers.",
		image: "SME.png",
	},
	{
		title: "LOANS",
		description:
			"Consumer Finance, SME Finance, Vehicle Finance, Personal Loan,Home and Gold Loan, E-Commerce, Commercial Lending.",
		image: "loan.png",
	},
];

const FeatureItem = ({ feature }) => {
	return (
		<div className="ezy__featured13-item position-relative p-4 p-lg-5">
			<img
				src={feature.image}
				alt=""
				className="img-fluid ezy__featured13-banner mb-4"
			/>
			<h4 className="ezy__featured13-title fs-4 fw-bold mb-3">
				{feature.title}
			</h4>
			<p className="ezy__featured13-content mb-0">{feature.description}</p>
		</div>
	);
};

FeatureItem.propTypes = {
	feature: PropTypes.object.isRequired,
};

const Feature13 = () => {
	return (
		<section className="ezy__featured13" >
			<div
				className="section-title text-center position-relative mx-auto"
				style={{ maxWidth: 600 }} // Ensure proper maxWidth
			>
				<h2 className="text-center" data-aos="fade-up">
					<span style={{ color: "purple" }}>Our</span>
					<span style={{ color: "red" }}> Features</span>
				</h2>
			</div>
			
			<Container fluid> {/* Corrected `fluid` */}
				<Row className="justify-content-center text-center" data-aos="zoom-in-down" data-aos-duration="3000">
					{features.map((feature, i) => (
						<Col 
							xs={12} // Full-width on extra small devices (mobile)
							sm={6}  // Half-width on small devices (tablets)
							md={4}  // Third-width on medium and larger devices (laptops, desktops)
							className="mt-4" 
							key={i}
						>
							<FeatureItem feature={feature} />
						</Col>
					))}
				</Row>
			</Container>
		</section>
	);
};


export default Feature13;