import React from "react";
import {Link} from 'react-router-dom';
import { RiTwitterXLine } from "react-icons/ri";

const Footer=()=>{
    function closeSection(){
        document.getElementById('footer').style.display='none';
    }
    
    
    
    return(
<>
<footer style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}}>
<div className="container-fluid" style={{backgroundColor:"#54626F"}}>
    <div className="row">
      <div className="col-xl-3 col-lg-4 col-md-6">
        <div>
       {/* <img src="COEQUAL_NIDHI.png" className="ms-3" height="100px" width="200px" alt=""/> */}
          <ul style={{listStyleType:"none"}}>
        <li className="text-light mt-4 inner">
          <h4 className="text-white">Registered Office</h4>
          <span><i className="fa fa-map-marker mt-2"></i> #101, 'A' Block, Sarovar Mansion<br/>
          1st Floor, Dodda Banaswadi Main Road,<br/>
          Annaiah Reddy Layout, Banswadi,<br/>
          Bangalore, Karnataka 560 043
          </span>
        </li>
      
      </ul>
      
        </div>
      </div>
      <div className="col-xl-2 offset-xl-1 col-lg-2 col-md-6">
        <div className="mt-4 inner text-white">
          <h4>Quick Links</h4>
          <ul className="list-unstyled mt-2">
            {/*<li>
              <Link to="#" class="text-decoration-none text-light text-uppercase ">Home</Link>
    </li> */}
            <li className="mt-2">
              <Link to="/aboutus" class="text-decoration-none text-light text-uppercase ">About Us</Link>
            </li>
            <li className="mt-2">
              <Link to="/gallery" class="text-decoration-none text-light text-uppercase ">Gallery</Link>
            </li>
            <li className="mt-2">
              <Link to="/carer" class="text-decoration-none text-light text-uppercase ">Career</Link>
            </li>
            <li className="mt-2">
              <Link to="/contactus" class="text-decoration-none text-light text-uppercase ">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-6">
        <div className="mt-4 inner text-white">
          <h4>Schemes</h4>
          <ul class="list-unstyled mt-2">
            <li className="mt-2">
              <a href="#" class="text-decoration-none text-light p-1">Home and Gold Loan</a>
            </li>
            <li className="mt-2">
              <a href="#" class="text-decoration-none text-light p-1">Vehicle Finance</a>
            </li>
            <li className="mt-2">
              <a href="#" class="text-decoration-none text-light p-1">FD and Investment</a>
            </li>
            <li className="mt-2">
              <a href="#" class="text-decoration-none text-light p-1">Vendor Financing</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-5">
        <div className="inner text-white" >
          <h4 className="mt-3 pt-2">Contact Us</h4>
          <ul class="list-unstyled mt-2">
      <li>
     <a href="mailto:support@coequalnidhi.com" className="text-decoration-none text-light" ><span><i className=" fa fa-envelope"></i> support@coequalnidhi.com
          </span>
          </a> 
        </li>
        <li>
          
        <a className="text-decoration-none text-light" href="tel:+918029909931"><span><i className="fa fa-phone"></i> +91-80-29909931</span></a><br/>
        <a className="text-decoration-none text-light" href="tel:+918029909931"><span><i className="fa fa-phone"></i> +91-80-29909932</span></a><br/>
          
        </li> 
        <li>
        <a className="text-decoration-none text-light" href="www.coequalnidhi.com"><span><i className="fa fa-globe"></i> www.coequalnidhi.com</span></a><br/>
        </li>
     </ul>

          
     
     
         {/* <div>
            <form action="https://formsubmit.co/support@coequalnidhi.com" method="POST">
            <input type="text" class="form-control" Placeholder="Enter Your Email"/>
            <button class="btn btn-danger mt-3">Send</button>
            </form>
            
            
    </div> */}
        
        </div>
      </div>
    </div>
    <div class="text-center">
    
   {/* <u className="text-light"><h4 className="mt-1 pt-2 text-dark">Follow Us</h4></u> */}
<div className='d-inline-flex align-items-center mt-4' style={{height:45}}>
  <span><b className="fs-4 pt-4 text-white mt-4">Follow Us : &nbsp;</b></span>
      <a className='btn btn-sm btn-outline-light btn-lg-square  me-2' href="https://twitter.com/COEQUALNIDHI">
      <RiTwitterXLine className="fab fa-2x fw-normal" />
      </a>
     </div>
     <div className='d-inline-flex align-items-center' style={{height:45}}>
      <a className='btn btn-sm btn-outline-light btn-lg-square me-2' href="https://www.facebook.com/coequal.nidhi.limited">
        <i className='fab fa-facebook-f fw-normal fa-2x'></i>
      </a>
     </div>
     <div className='d-inline-flex align-items-center' style={{height:45}}>
      <a className='btn btn-sm btn-outline-light  btn-lg-square me-2' href="https://www.linkedin.com/feed/?trk=sem-ga_campid.14650114788_asid.127961666300_crid.651859540485_kw.linkedin%20login_d.c_tid.kwd-12704335873_n.g_mt.e_geo.9062033">
        <i className='fab fa-linkedin-in fw-normal fa-2x'></i>
      </a>
     </div>
     <div className='d-inline-flex align-items-center' style={{height:45}}>
      <a className='btn btn-sm btn-outline-light btn-lg-square me-2' href="https://www.instagram.com/coequalnidhilimited/">
        <i className='fab fa-instagram fw-normal fa-2x'></i>
      </a>
     </div>
     <div className='d-inline-flex align-items-center' style={{height:45}}>
      <a className='btn btn-sm btn-outline-light btn-lg-square  me-2' href="https://www.youtube.com/channel/UCT2D6b3Gs-t93Kms3IyfIQA">
        <i className='fab fa-youtube fw-normal fa-2x'></i>
      </a>
     </div>

      <div class="copyright text-center mt-3">
      <Link className="text-light" to="/privacy"> <h6>Our Terms & Conditions
       </h6></Link>
      <Link className="text-light" to="/privacy"> <h6>
      <span className="text-light">Privacy Policy</span> </h6></Link>
      </div>
    </div>

  </div>
</footer>



<footer className="footer" style={{fontFamily:"Trebuchet MS"}}>

<p className="text-light text-center mt-3">© 2022 <a href="#">COEQUAL NIDHI LIMITED.</a> All Rights Reserved.</p>
<p className="text-light text-center mt-3">Developed and maintained by <a href="www.coequalnidhi.com" target="_blank" className="text-primary"><b>COEQUAL NIDHI LIMITED</b></a></p>

</footer>

{/*<footer  id="footer" className="footer p-1 position-sticky">
<p className="text-light text-center">By clicking on Agree you were accepted our cookies<button
onClick={()=>closeSection()} className="btn border-none text-sm text-primary">I Agree</button>
<button className="btn float-end text-info" onClick={()=>{closeSection()}}></button></p>
    </footer> */}



</>
)
};
export default Footer;
