import React, { Fragment, useState } from "react";
import { Card, Carousel, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import './md.css';

// const Md1=()=> {
//   const [index, setIndex] = React.useState(0);
//   const timeoutRef = React.useRef(null);

//   function resetTimeout() {
//     if (timeoutRef.current) {
//       clearTimeout(timeoutRef.current);
//     }
//   }

//   React.useEffect(() => {
//     resetTimeout();
//     timeoutRef.current = setTimeout(
//       () =>
//         setIndex((prevIndex) =>
//           prevIndex === colors.length - 1 ? 0 : prevIndex + 1
//         ),
//       delay
//     );

//     return () => {
//       resetTimeout();
//     };
//   }, [index]);

//   return (
//     <><div className='bg-light m-4'>
//      <div class="section-title text-center position-relative mx-auto" style={{maxwidth: 600}}>
            
//             <h2 className='text-center pt-3'><span style={{color:'purple'}}>BOARD</span><span class="text-danger"> OF</span><span style={{color: 'purple'}}> DIRECTORS</span></h2>
            
//                           {/*  <h5 class="mb-0">Professionals Ready To Help Your Business Needs</h5> */}
//                         </div>
//     <div className="slideshow">
//       <div
//         className="slideshowSlider"
//         style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
//       >
//         <div className='slide'>
//         <img src="SIR.jpg" className='border rounded-circle' height="200px" width="200px" alt="" />
       
//        <p className='ps-2 md' style={{textAlign: 'justify'}}>
//        <i class="fa fa-quote-left"></i><b><i> COEQUAL NIDHI LIMITED  </i></b>is a registered 
//                         company by a team of seasoned financial<br/> professionals. we ensure the best in 
//                         financial services to our members in order to encourage<br/> saving habits and monetary discipline 
//                         that are sustainable and productive.
//                         <i class="fa fa-quote-right"></i>
//                       </p>
       
//                       <h5>Mr.Jayanthinathan Prabakaran</h5>
//                       <h6>Founder & Managing Director</h6>
        
//         </div>
//         <div className='slide'>
      
//         <img src="MAM.jpg" className='border rounded-circle' height="200px" width="200px" alt="" />
//         <p className='ps-2 md' style={{textAlign: 'justify'}}>
//         <i class="fa fa-quote-left"></i><b><i> COEQUAL NIDHI LIMITED  </i></b>
//                          is categorized as a non-banking Indian finance company and <br/>is recognized under section 406 
//                          of the Companies Act, 2013. Our business model operates<br/> on the principle of transacting 
//                          within a closed system of members.
//                         <i class="fa fa-quote-right"></i>
//                       </p>
//                       <h5>Mrs.Rekha Sounderraj</h5>
//                       <h6>Co-Founder & Joint Director</h6>
//         </div>
//         <div className='slide'>
//         <img src="SIR.jpg" className='border rounded-circle' height="200px" width="200px" alt="" />
//         <p className='ps-2 md' style={{textAlign: 'justify'}}>
//         <i class="fa fa-quote-left"></i><b><i> COEQUAL NIDHI LIMITED  </i></b>is a registered 
//                         company by a team of seasoned financial<br/> professionals.We ensure the best in 
//                         financial services to our members in order to encourage<br/> saving habits and monetary discipline 
//                         that are sustainable and productive.
//                         <i class="fa fa-quote-right"></i>
//                       </p>
//                       <h5>Mr.Jayanthinathan Prabakaran</h5>
//                       <h6>Founder & Managing Director</h6>
        
//         </div>
     
//       </div>
      

//       <div className="slideshowDots">
//         {colors.map((_, idx) => (
//           <div
//             key={idx}
//             className={`slideshowDot${index === idx ? " active" : ""}`} hidden
//             onClick={() => {
//               setIndex(idx);
//             }}
//           ></div>
//         ))}
//         </div> 
//     </div>
//     </div>
//   </>
//   );
// }
// export default Md1;

const testimonialList = [
	{
		img: "SIR.jpg",
		name: "Mr.Jayanthinathan Prabakaran",
		position: "Founder & Managing Director",
		content:
			"COEQUAL NIDHI LIMITED is a registered company by a team of seasoned financial professionals. we ensure the best in financial services to our members in order to encourage saving habits and monetary discipline that are sustainable and productive.",
	},
	{
		img: "MAM.jpg",
		name: "Mrs.Rekha Sounderraj",
		position: "Co-Founder & Joint Director",
		content:
			"COEQUAL NIDHI LIMITED is categorized as a non-banking Indian finance company and is recognized under section 406 of the Companies Act, 2013. Our business model operates on the principle of transacting within a closed system of members.",
	},
];

const TestimonialItem = ({ testimonial }) => {
	return (
		<Fragment>
			<p className="position-relative ezy__testimonial18-content mb-4 mb-md-5 text-center">
				<FontAwesomeIcon
					icon={faQuoteLeft}
					className="ezy__testimonial18-quote"
				/>
				{testimonial.content}
				<FontAwesomeIcon
					icon={faQuoteRight}
					className="ezy__testimonial18-quote"
				/>
			</p>
			<div className="ezy__testimonial18-item">
				<Card.Body className="text-center">
					<div className="me-3">
						<img
							src={testimonial.img}
							alt={testimonial.name}
							className="ezy__testimonial18-img rounded-circle mb-3"
						/>
					</div>
					<div>
						<h4 className="ezy__testimonial18-name mb-2">{testimonial.name}</h4>
						<p className="ezy__testimonial18-title mb-0">
							{testimonial.position}
						</p>
					</div>
				</Card.Body>
			</div>
		</Fragment>
	);
};

TestimonialItem.propTypes = {
	testimonial: PropTypes.object.isRequired,
};

const Testimonial18 = () => {
	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex) => setIndex(selectedIndex);

	return (
		<section className="ezy__testimonial18" style={{
			backgroundImage: "url('http://www.coequalservices.com/img/background.jpeg')",
			backgroundPosition: "center bottom",
			backgroundSize: "cover",
			marginBottom: "30px"
		  }}>
			<Container fluid  data-aos="fade-up"
     data-aos-anchor-placement="top-bottom" data-aos-duration="2000">
			<div className=" ">
							<div className="section-title text-center position-relative mx-auto" style={{ maxWidth: 600 }} >
								<h2 className="text-center ">
									<span style={{ color: 'purple' }}>BOARD</span>
									<span className="text-danger"> OF</span>
									<span style={{ color: 'purple' }}> DIRECTORS</span>
								</h2>
							</div>
						
						<Carousel
							activeIndex={index}
							onSelect={handleSelect}
							controls={false}
							indicators={true}
						>
							{testimonialList.map((testimonial, i) => (
								<Carousel.Item key={i}>
									<TestimonialItem testimonial={testimonial} />
								</Carousel.Item>
							))}
				</Carousel> 
				</div>
			</Container>
		</section>
	);
};

export default Testimonial18;