import React from 'react';

const Md=()=>{
    return(
        <>
      

    <div class="container-fluid py-2 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-3">
            <div class="section-title text-center position-relative mx-auto" style={{maxwidth: 600}}>
            
<h2 className='text-center'><span style={{color:'purple'}}>BOARD</span><span class="text-danger"> OF</span><span style={{color: 'purple'}}> DIRECTORS</span></h2>

                <h5 class="mb-0">Professionals Ready To Help Your Business Needs</h5>
            </div>
            <div class="row g-5 offset-2 ps-2">
                <div class="col-lg-5 slideInUp" data-aos="flip-up" data-aos-duration="1000" data-wow-delay="0.3s">
                    <div class=" bg-light rounded overflow-hidden ">
                        <div class="team-img position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="SIR.jpg" alt=""/>
                         
                        </div>
                        <div class="text-center py-4">
                            <h4 class="text-primary">Mr.Jayanthinathan Prabakaran</h4>
                            <p class="text-uppercase m-0">Founder & Managing Director</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5  slideInUp" data-aos="flip-up" data-aos-duration="1000" data-wow-delay="0.6s">
                    <div class="bg-light rounded overflow-hidden">
                        <div class="team-img position-relative overflow-hidden">
                            <img class="img-fluid w-100"  src="MAM.jpg" alt=""/>
                          
                        </div>
                        <div class="text-center py-4 ">
                            <h4 class="text-primary">Mrs.Rekha Sounderraj</h4>
                            <p class="text-uppercase m-0">Co-Founder & Joint Director</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
   


   
   
        </>
    )
};
export default Md;