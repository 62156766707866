import React from 'react';
import {Link} from 'react-router-dom';
import ReactPlayer from "react-player";
import Header from './header';
import Footer from './footer';
import Header1 from './header1';

const Kamadenu=()=>{
return(
  <>
  <Header1/>
  <div className='text-center mt-5 pt-5'>
	<div className='centercontain'>
	{/*<h1 className='fix text-danger'>RETURN ON INVESMENT</h1> */}
    <img className='' width="100%" height="350px" src="https://www.realgrowthnidhi.com/images/Daily-deposit-banner.jpg" alt=""/>
	</div>
 </div>
 <div class="container">
	<div class="row mt-5">
			<div class="col-lg-7 inner" style={{fontFamily: "Raleway"-"sans-serif", fontWeight: "900", fontStyle: "normal"}}>
			
					<h3><span className='text-primary'>KAMADHENU DAILY</span><span style={{color:"purple"}}> DEPOSIT SCHEME</span></h3>
					<p style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}}>
          It is a monetory deposit scheme introduced to help dailywage earners, small traders,<br/> To 
inculcate saving habits and also as a way of funding their bigger capital requirements.                    
					</p>
				
				
			</div>
			<div class="col-lg-5" style={{ borderRadius: "35px"}}>
			<ReactPlayer
                url="https://www.youtube.com/watch?v=Q6Tj1OAAng0"
                className="img-fluid"
                data-aos="flip-up"
                width="550px"
              />			
			  </div>
			
	</div>
	<div class="row mt-5">
		<div class="container">
			<div class="middle" style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}}>
				<div class="table-responsive">
				  <table class="table table-bordered">
				    <thead className='bg-primary'>
				      <tr>
				        <th>Period</th>
				        <th>Rate of Interest<br/>
						(General Category)
						</th>
				        <th>Rate of Interest<br/>
						(Senior Citizen)
						</th>
				      
				      </tr>
				    </thead>
				    <tbody>
				      <tr>
				        <td>12 Months</td>
				        <td>9.50%</td>
				        <td>10.00%</td>
				        
				      </tr>
				      <tr>
				        <td>24 Months</td>
				        <td>10.00%</td>
				        <td>10.50%</td>
				       
				      </tr>
				      <tr>
				        <td>36 Months</td>
				        <td>10.50%</td>
				        <td>11.00%</td>
				       
				      </tr>
				      <tr>
				        <td>48 Months</td>
				        <td>11.00%</td>
				        <td>11.50%</td>
				       
				      </tr>
				      <tr>
				        <td>60 Months</td>
				        <td>11.50%</td>
				        <td>12.00%</td>
				       
				      </tr>
				     
				    </tbody>
				  </table>
				</div>
			</div>
		</div>
	</div>
	<div class="row  mb-2 mt-2">
		<div class="container">
			<div class="col-md-12" style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}}>
				<div class="inner mt-2">
					<h3 style={{color:"purple"}}>Features<span> And Benefits</span></h3>
					<p><i class="fa fa-angle-double-right"></i> The amount may be as small as Rs.100/-.</p>
					<p><i class="fa fa-angle-double-right"></i> Interest rates for general category 9.50% to 11.50%.</p>
					<p><i class="fa fa-angle-double-right"></i>	Interest rates for senior citizen 10% to 12%.</p>
					<p><i class="fa fa-angle-double-right"></i> The minimum denomination of the scheme is Rs.100/- per day and in multiples of 100. </p>
					
				</div>
			</div>
		</div>		
	</div>
  </div>
    <Footer/>
    </>
)
};
export default Kamadenu;