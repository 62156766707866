import React from "react";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import "./aboutus.css";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import Swiperimage from "./swiper";
import ContactOne from "./contactone";
import AOS from "aos";
import "aos/dist/aos.css";
import Career from "./carer";
import Feature34 from "./features";
import "./App.css";
import SimpleForm from "./SimpleForm";
import Service14 from "./service";
import AboutUs13 from "./aboutus";
import $ from "jquery";
import { FaSquareXTwitter } from "react-icons/fa6";
import SideIconBar from "./sideiconbar";

// import Md from "./md";

import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import Header1 from "./header1";
import Flipper from "./flipper";
import Header2 from "./header2";
import Chatmobile from "./chatbotmob";
import Md1 from "./md1";
import Counter from "./counter";
import Chat from "./chatbox";

const steps = [
  {
    id: "0",
    message: "Hi Welcome to COEQUAL NIDHI LIMITED!",
    // This calls the next id
    // i.e. id 1 in this case
    trigger: "member",
  },

  {
    id: "member",
    message: "Be A Member Of COEQUAL NIDHI LIMITED👏",
    trigger: "member-option",
  },
  {
    id: "member-option",
    options: [
      { value: "y", label: "Yes", trigger: "yes-response" },
      { value: "n", label: "No", trigger: "no-response" },
    ],
  },
  {
    id: "yes-response",
    message: "Great! Please Enter your Mobile Number😊",
    link: "https://openai.com",
    trigger: "user",
  },
  {
    id: "user",
    user: true,
    trigger: 7,
    validator: (value) => {
      if (isNaN(value)) {
        return "value must be a number";
      } else if (value < 0) {
        return "Enter the correct Mobile Number";
      }

      return true;
    },
  },
  {
    id: "no-response",
    message: "Sorry to hear this.",
    trigger: "askname",
  },

  {
    id: "askname",
    message: "Can i know your Name please...",
    trigger: "name",
  },
  {
    id: "name",
    user: true,
    trigger: "greet",
  },
  {
    id: "greet",
    message: "Hello {previousValue}! how can we help you?? ",
    trigger: 3,
  },
  {
    id: "3",
    message: "What is the issue you are facing?",
    trigger: 4,
  },
  {
    id: "4",
    user: true,
    trigger: "1",
  },
  {
    id: "1",
    message:
      "Can You Please enter your mobile number 📱, So we can contact you",
    trigger: "mob",
  },
  {
    id: "mob",
    user: true,
    trigger: "7",
    validator: (value) => {
      if (isNaN(value)) {
        return "value must be a number";
      } else if (value < 0) {
        return "Enter the correct Mobile Number";
      }

      return true;
    },
  },

  {
    id: "7",
    message: "Thank you..Will contact you soon...",
    trigger: "last",
  },
  {
    id: "last",
    user: true,
    trigger: "end",
  },
  {
    id: "end",
    message:
      "Try to ask something else or Please contact Us😊 (https://wa.me/9606456983?text=WELCOME TO COEQUAL NIDHI LIMITED)",
  },
];

// Creating our own theme
const theme = {
  background: "#fff",
  headerBgColor: "#197B22",
  headerFontSize: "20px",
  botBubbleColor: "#0F3789",
  headerFontColor: "white",
  botFontColor: "white",
  userBubbleColor: "#FF5733",
  userFontColor: "white",
};

// Set some properties of the bot
const config = {
  botAvatar: "coequallogo1.png",
  floating: true,
  height: "410px",
};

const Body = () => {
  let [showChat, setShowChat] = useState(false);

  const startChat = () => {
    setShowChat(true);
  };
  const hideChat = () => {
    setShowChat(false);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  function stop() {
    document.getElementById("marquee").stop();
  }
  function start() {
    document.getElementById("marquee").start();
  }

  return (
    <>
      <div
        class="scroll-to-top scroll-to-target"
        data-target="html"
        style={{ margginTop: "600px" }}
      >
        <span class="fa fa-long-arrow-up"></span>
      </div>

      <div className="whatsapp">
        <a
          classname="text-danger col-lg-6 text-center"
          href="https://wa.me/9606456983?text=WELCOME TO COEQUAL NIDHI LIMITED"
        >
          <img src="wp.gif" alt="" width="60px" height="60px" />
        </a>
      </div>

      <div className="bot">
        <div className="botchat" style={{ display: showChat ? "" : "none" }}>
          <SimpleForm></SimpleForm>
        </div>
        {/* <div> {showChat ? <SimpleForm></SimpleForm> : null} </div> */}
        <div className="">
          {!showChat ? (
            <button
              className="btn text-decoration-none"
              id="btn1"
              onClick={() => startChat()}
            >
              <img
                src=".\chatbot-marketing.gif"
                id="hide"
                height="100px"
                width="100px"
                alt=""
              />
            </button>
          ) : (
            <button className="btn1" onClick={() => hideChat()}>
              <i className="	fas fa-comment-slash fa-2x text-primary"></i>
              <b className="text-primary">Close </b>
            </button>
          )}
        </div>
      </div>

      {/*<Header/> */}
      
      {/* chat box*/}

      <div className="chatbot" id="chatdesk">
        <ThemeProvider theme={theme}>
          <ChatBot
            // This appears as the header
            // text for the chat bot

            headerTitle="Chat with Us!"
            height="100px"
            steps={steps}
            {...config}
          />
        </ThemeProvider>
      </div>

      
      <Header1 />

{/* 
      {/*courocel start */}
 
      <div
        id="carouselExampleCaptions"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            class="active invisible"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            class="active invisible"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            class="active invisible"
            aria-label="Slide 3"
          ></button>
        </div>
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner" style={{ height: '100vh' }}>
            <div className="carousel-item active mt-3 pt-5">
              <img
                src="hero1.png"
                className="d-block w-100"
                alt="Slide 1"
                style={{
                  height: 'auto',
                  maxHeight: '100vh',
                }}
              />
              <div
                className="position" data-aos="zoom-in-down" data-aos-duration="1000"
                style={{ 
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                
                <div className="p-2 bg-light opacity-75 shadow rounded border"  behavior="alternate" style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '55%',
                  }}>
                    
                  <h1
                    
                    style={{
                      color: 'purple',
                      fontFamily: 'Supreme-Bold',
                      fontWeight: 'bolder',
                      justifyContent: 'center',
                      // WebkitTextStroke: '1px white',
                    }}
                  >
                    WELCOME TO COEQUAL NIDHI LIMITED
                  </h1>
                  <h3 className="Ranade-Bold" style={{ color: 'red', fontFamily: 'Supreme-Bold'}}>(Creating a Brighter Tomorrow)</h3>
                </div>
              </div>
            </div>

            <div className="carousel-item">
                <img
                  src="hero(2).png"
                  className="d-block w-100 mt-4 pt-5"
                  alt="Slide 2"
                  style={{
                    height: 'auto',
                    maxHeight: '100vh',
                  }}
                />
                <div
                  className="content-center bg-light opacity-75 shadow rounded border" 
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '10px',
                  }}
                >
                  <h1 className="text-dark" data-aos="fade-up"
                  data-aos-duration="2000">Make The Smart Move Of Investment</h1>
                </div>
              </div>

              <div className="carousel-item">
                <img
                  src="hero (3).png"
                  className="d-block w-100 mt-4 pt-5"
                  alt="Slide 3"
                  style={{
                    height: 'auto',
                    maxHeight: '100vh',
                  }}
                />
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{
                    position: 'absolute',
                    bottom: '20px',
                    left: '20px',
                    right: '20px',
                    padding: '10px',
                  }}
                >
                  <div
                    className="bottomleft bg-light opacity-75 shadow rounded border"
                    style={{
                      padding: '10px',
                      flex: '0 1 auto',
                    }}
                  >
                    <div data-aos="fade-top">
                      <h3 className="text-primary text-center">
                        <b>Our Mission</b>
                      </h3>
                    </div>
                    <h4 className="text-center">"Grow Through Life"</h4>
                  </div>

                  <div
                    className="bottomright bg-light opacity-75 shadow rounded border"
                    style={{
                      padding: '10px',
                      flex: '0 1 auto',
                    }}
                  >
                    <h3 className="text-primary text-center">
                      <b>Our Vision</b>
                    </h3>
                    <h4 className="text-center">"Quality and Equality"</h4>
                  </div>
                </div>
              </div>
          </div>
        </div>

        <marquee
          id="marquee"
          className="blink"
          style={{
            width: '100%',
            backgroundColor: '#FFF3E0'
          }}
          behavior="scroll"
          scrollAmount={10}
          onMouseOver={stop}
          onMouseOut={start}
          >
          <h3>
            <span>
              <Link className="text-decoration-none" to="/saving">
                Savings Account ---
              </Link>
            </span>
            <span>
              <Link className="text-decoration-none" to="/fixeddeposite">
                Fixed Deposit ---
              </Link>
            </span>
            <span>
              <Link className="text-decoration-none" to="/recurringdp">
                {" "}
                Recurring Deposit ---
              </Link>
            </span>
            <span>
              <Link className="text-decoration-none" to="/roi">
                {" "}
                Return On Investment ---
              </Link>
            </span>
            <span>
              <Link className="text-decoration-none" to="/kamadenu">
                {" "}
                Kamadhenu Daily Deposit Scheme ---
              </Link>
            </span>
            <span>
              <Link className="text-decoration-none" to="/kalpa">
                {" "}
                Kalpavruksha Scheme---
              </Link>
            </span>
            <span>
              <Link className="text-decoration-none" to="/fixeddeposite">
                {" "}
                Fixed Deposit ---
              </Link>
            </span>
            <span>
              <Link className="text-decoration-none" to="/recurringdp">
                {" "}
                Recurring Deposit ---
              </Link>
            </span>
            <span>
              <Link className="text-decoration-none" to="/roi">
                {" "}
                Return On Investment ---
              </Link>
            </span>
            <span>
              <Link className="text-decoration-none" to="/kamadenu">
                {" "}
                Kamadhenu Daily Deposit Scheme ---
              </Link>
            </span>
            <span>
              <Link className="text-decoration-none" to="/kalpa">
                {" "}
                Kalpavruksha Scheme---
              </Link>
            </span>
          </h3>
        </marquee>

        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      
      {/* <section> */}
      {/* <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        {/* Carousel Indicators */}
        {/* <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div> */}

        {/* Carousel Items */}
        {/* <div className="carousel-inner"> */}
          {/* First Slide */}
          {/* <div className="carousel-item active">
            <img
              src="hero1.png"
              className="d-block w-100"
              alt="Slide 1"
              style={{
                height: '100vh',
                objectFit: 'cover',
                width: '100%',
              }}
            />
            <div
              className="carousel-caption d-none d-md-block"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'auto',
                textAlign: 'center',
              }}
            >
              <div className="p-2 bg-light opacity-75 shadow rounded">
              <h1 style={{ color: 'purple', fontWeight: 'bolder', fontSize: '2rem' }}>
              WELCOME TO COEQUAL NIDHI LIMITED
            </h1>
                <h3
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                    fontSize: 'clamp(14px, 4vw, 28px)',
                  }}
                >
                  (Creating a Brighter Tomorrow)
                </h3>
              </div>
            </div>
          </div> */}

          {/* Second Slide */}
          {/* <div className="carousel-item">
            <img
              src="hero2.png"
              className="d-block w-100"
              alt="Slide 2"
              style={{
                height: '100vh',
                objectFit: 'cover',
                width: '100%',
              }}
            />
            <div
              className="carousel-caption d-none d-md-block"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'auto',
              }}
            >
              <div className="p-2 bg-light opacity-75 shadow rounded">
                <h1
                  className="text-dark display-5"
                  style={{ fontSize: 'clamp(20px, 5vw, 45px)' }}
                >
                  Make The Smart Move Of Investment
                </h1>
              </div>
            </div>
          </div> */}

          {/* Third Slide */}
          {/* <div className="carousel-item">
            <img
              src="hero (3).png"
              className="d-block w-100"
              alt="Slide 3"
              style={{
                height: '100vh',
                objectFit: 'cover',
                width: '100%',
              }}
            />
            <div
              className="d-flex justify-content-around align-items-center"
              style={{
                position: 'absolute',
                bottom: '20px',
                left: '20px',
                right: '20px',
                padding: '10px',
              }}
            >
              <div className="bg-light opacity-75 shadow rounded p-2">
                <h3
                  className="text-primary text-center"
                  style={{ fontSize: 'clamp(18px, 4vw, 32px)' }}
                >
                  <b>Our Mission</b>
                </h3>
                <h4
                  className="text-center"
                  style={{ fontSize: 'clamp(14px, 3.5vw, 28px)' }}
                >
                  "Grow Through Life"
                </h4>
              </div>

              <div className="bg-light opacity-75 shadow rounded p-2">
                <h3
                  className="text-primary text-center"
                  style={{ fontSize: 'clamp(18px, 4vw, 32px)' }}
                >
                  <b>Our Vision</b>
                </h3>
                <h4
                  className="text-center"
                  style={{ fontSize: 'clamp(14px, 3.5vw, 28px)' }}
                >
                  "Quality and Equality"
                </h4>
              </div>
            </div>
          </div>
        </div> */}

        {/* Carousel Controls */}
        {/* <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div> */}

      {/* Marquee Section */}
      {/* <marquee
        className="blink"
        style={{
          width: '100%',
          backgroundColor: '#FFF3E0',
          padding: '10px 0',
        }}
        behavior="scroll"
        scrollamount="8"
      >
        <h3>
          <span>
            <Link className="text-decoration-none" to="/saving">
              Savings Account ---
            </Link>
          </span>
          <span>
            <Link className="text-decoration-none" to="/fixeddeposite">
              Fixed Deposit ---
            </Link>
          </span>
          <span>
            <Link className="text-decoration-none" to="/recurringdp">
              Recurring Deposit ---
            </Link>
          </span>
          <span>
            <Link className="text-decoration-none" to="/roi">
              Return On Investment ---
            </Link>
          </span>
          <span>
            <Link className="text-decoration-none" to="/kamadenu">
              Kamadhenu Daily Deposit Scheme ---
            </Link>
          </span>
          <span>
            <Link className="text-decoration-none" to="/kalpa">
              Kalpavruksha Scheme ---
            </Link>
          </span>
        </h3>
      </marquee> */}
    {/* </section> */}

      {/*courocel end */}

      {/*side enquiry form starts*/}
      {/* <div className="sidebar-form">
        <div className='call-action'>
          <span>ENQUIRY FORM</span>
        </div>
        <h3>Enquiry Form</h3>
        <form action="https://formsubmit.co/support@coequalnidhi.com" method="POST">
          <div className='form-group'>
            <label for="">Full Name</label>
            <input type="text" name="name" id="" class="form-control" />
          </div>
          <div className='form-group'>
            <label for="">Email</label>
            <input type="email" name="email" id="" class="form-control" />
          </div>
          <div className='form-group'>
            <label for="">Phone No</label>
            <input type="text" name="number" id="" class="form-control" />
          </div>
          <div className='form-group'>
            <label for="">Plan</label>
            <input type="text" name="plan" id="" class="form-control" />
          </div>
          <button type="submit">Send</button>
      </form>

      </div>*/}
      {/*side enquiry form end*/}

      {/*left side social media*/}
      {/* <div class="icon-bar">
        <a
          href="https://www.facebook.com/coequal.nidhi.limited"
          class="facebook"
        >
          <i class="fa fa-facebook"></i>
        </a>
        <a href="https://twitter.com/COEQUALNIDHI" class="twitter">
          <FaSquareXTwitter  />  
        </a>
        <a href="https://www.instagram.com/coequalnidhilimited/" class="google">
          <i class="fa fa-instagram"></i>
        </a>
        <a
          href="https://www.linkedin.com/feed/?trk=sem-ga_campid.14650114788_asid.127961666300_crid.651859540485_kw.linkedin%20login_d.c_tid.kwd-12704335873_n.g_mt.e_geo.9062033"
          class="linkedin"
        >
          <i class="fa fa-linkedin"></i>
        </a>
        <a
          href="https://www.youtube.com/channel/UCT2D6b3Gs-t93Kms3IyfIQA"
          class="youtube"
        >
          <i class="fa fa-youtube"></i>
        </a>
      </div> */}

      

      {/*about us */}
      {/* <section
        data-aos="fade-up"
        data-aos-duration="4000"
        id="aboutus"
        className="container  mb-2"
      >
        <div className="section-title">
          <h2 className="fw-bold text-primary text-uppercase">
            <p className="text-danger">
              ABOUT<span style={{ color: "purple" }}> US</span>{" "}
            </p>
          </h2>
        </div>
        <div className="row text-center">
          <div className="col-lg-5">
            <div className="mb-3 ps-4"> */}
              {/*  <p className='text-start'>
               <b>COEQUAL SERVICES </b>Is Established in the year 2022.<br /> */}
              {/*<br />
                <b>Our Mission</b>:“Grow through Life”<br />
                <br />
                <b>Our Vision</b>:“Quality and Equality”<br />
    <br /> */}
              {/*  <b>We Value</b>: Equal Opportunity<br />
                &emsp;&emsp;&emsp;&emsp;&emsp;Responsibility<br />
                &emsp;&emsp;&emsp;&emsp;&emsp;Ownership<br />
                &emsp;&emsp;&emsp;&emsp;&emsp;Quality<br />
                <br />

  </p> */}
              {/* <p className="text-start">
                <p className="justify-content" id="para">
                  {" "}
                  <b>COEQUAL NIDHI LIMITED</b> is one of the most diversified
                  deposit- taking financial in India. It lends portfolio across
                  retail, SMEs and commercial customers in urban and rural
                  India. It accepts public and corporate deposits and offers a
                  variety of financial services products to its customers. Fixed
                  Deposit, Recurring Deposit, Return on Investment, Kamadhenu
                  and Kalpavruksha are our Atractive Deposit Plans.
                </p>
                <p className="text-start" id="para">
                  <b>We Value</b>: Equal Opportunity
                  <br />
                  &emsp;&emsp;&emsp;&emsp;&emsp;Responsibility
                  <br />
                  &emsp;&emsp;&emsp;&emsp;&emsp;Ownership
                  <br />
                  &emsp;&emsp;&emsp;&emsp;&emsp;Quality
                  <br />
                </p>
              </p>
              <ReactPlayer
                url="https://youtu.be/mNlbT_DxaeM"
                className="img-fluid"
                data-aos="flip-up"
                width="550px"
              />
              <a
                href="https://www.coequalservices.com/"
                className=" btn rouded shadow btn btn-secondary m-4 "
              >
                Learn More
              </a>
            </div>
          </div>
          <div className="col-lg-7">
            <img
              src="coequalmulti.png"
              className="img-fluid bg-white"
              alt="COEQUAL INFOTECH PVT LTD"
              style={{ width: "750px" }}
            />
          </div>
        </div>
      </section> */}

{/* const AboutUs13 = () => {
	return ( */}
		{/* <section className="ezy__about13" id="ezy__about13">
			<Container>
				<Row className="justify-content-center align-items-center mt-5" data-aos="zoom-in-right"
                data-aos-duration="2000">
					<Col xs={12} // Full-width on extra small devices (mobile)
							sm={6}  // Half-width on small devices (tablets)
							md={4}  // Third-width on medium and larger devices (laptops, desktops)
							className="mt-4" >
						<div>
							<h1 className="ezy__about13-heading m" style={{ color: "black"}}>ABOUT US</h1>
							<hr className="ezy__about13-divider my-4" />
							<p className="ezy__about13-sub-heading mb-2 fw-bold"  style={{ color: "dark", overflow: "hidden"}}>
								<b>COEQUAL NIDHI LIMITED</b> is one of the most diversified deposit- taking financial in India. It lends portfolio across retail, SMEs and commercial customers in urban and rural India. It accepts public and corporate deposits and offers a variety of financial services products to its customers. Fixed Deposit, Recurring Deposit, Return on Investment, Kamadhenu and Kalpavruksha are our Atractive Deposit Plans.
							</p>
							<p className="ezy__about13-sub-heading mb-0 fw-bold">
								<b>We Value</b>: Equal Opportunity
                              <br />
                              &emsp;&emsp;&emsp;&emsp;&emsp;Responsibility
                              <br />
                              &emsp;&emsp;&emsp;&emsp;&emsp;Ownership
                              <br />
                              &emsp;&emsp;&emsp;&emsp;&emsp;Quality
                              <br />
							</p>
              <ReactPlayer
                url="https://youtu.be/mNlbT_DxaeM"
                className="img-fluid"
                width="450px"
                height="250px"
                style={{ borderRadius: '15px', overflow: 'hidden' }}
              />
              <a
                href="https://www.coequalservices.com/"
                className=" btn rouded shadow btn btn-secondary m-4 "
              >
                Learn More
              </a>
						</div>
					</Col>
					<Col xs={12} lg={6}>
						<div className="ezy__about13-bg-holder" />
						<div className="position-relative" data-aos="zoom-out-left" data-aos-duration="2000">
							<img
								src="coequalgroup.jpg"
								alt=""
								className="img-fluid"
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</section> */}

<section className="ezy__about13" id="ezy__about13">
  <Container
    fluid
    style={{
      backgroundImage: "url('http://www.coequalservices.com/img/background.jpeg')",
      backgroundPosition: "center bottom",
      backgroundSize: "cover",
      marginBottom: "30px",
    }}
  >
    <Row
      className="justify-content-center align-items-center "
      data-aos="zoom-in-right"
      data-aos-duration="2000"
    >
      {/* Left Column: Text Content */}
      <Col xs={12} lg={4} className="mb-4 mb-lg-4">
        <div>
          {/* Updated Heading Font Style */}
          <h1
            className="ezy__about13-heading"
            style={{
              color: "burlywood",
              fontFamily: "'Montserrat Extra Bold', sans-serif", // Add font style
              fontSize: "3rem", // Adjust font size for heading
            }}
          >
            ABOUT US
          </h1>
          <hr className="ezy__about13-divider my-4" />
          
          {/* Updated Subheading Font Style */}
          <p
            className="ezy__about13-sub-heading mb-2 fw-bold"
            style={{
              color: "black",
              fontFamily: "'Roboto', sans-serif", // Add font style for paragraph
              fontSize: "1.1rem", // Adjust size for readability
            }}
          >
            <b>COEQUAL NIDHI LIMITED</b> is one of the most diversified
            deposit-taking financial services in India. It lends across retail,
            SMEs, and commercial customers in both urban and rural India. It
            accepts public and corporate deposits, offering a variety of
            financial services, including Fixed Deposits, Recurring Deposits,
            Return on Investment, Kamadhenu, and Kalpavruksha deposit plans.
          </p>

          {/* Updated Values Section Font Style */}
          <p
            className="ezy__about13-sub-heading mb-0 fw-bold"
            style={{
              color: "black",
              fontFamily: "'Lato Bold', sans-serif", // Add font style for values
              fontSize: "1.2rem", // Slightly larger for emphasis
            }}
          >
            <b>We Value:</b>
            <br />
            &emsp;&emsp;Equal Opportunity
            <br />
            &emsp;&emsp;Responsibility
            <br />
            &emsp;&emsp;Ownership
            <br />
            &emsp;&emsp;Quality
          </p>

          {/* React Player with border radius */}
          <ReactPlayer
            url="https://youtu.be/mNlbT_DxaeM"
            className="img-fluid"
            width="450px" /* Make it fully responsive */
            height="250px" /* Let height adjust with width for responsiveness */
            style={{
              borderRadius: "15px",
              overflow: "hidden",
            }} /* Limit width */
          />
          <div className="d-flex justify-content-center mt-4">
            <a
              href="https://www.coequalservices.com/"
              className="btn btn-secondary shadow"
              style={{
                width: "150px",
                textAlign: "center",
                position: "relative",
                zIndex: "1000",
                fontFamily: "'Bebas Neue', sans-serif", // Add font style for the button
                fontSize: "1rem", // Adjust button text size
              }}
            >
              Learn More
            </a>
          </div>
        </div>
      </Col>

          {/* Right Column: Image Content */}
          <Col xs={12} lg={6} className="d-flex justify-content-center">
            <div className="position-relative" data-aos="zoom-out-left" data-aos-duration="2000">
              <img
                src="coequalgroup.jpg"
                alt="CoEqual Group"
                className="img-fluid"
                style={{ borderRadius: '15px', maxWidth: '100%', height: 'auto' }}  /* Fully responsive image */
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
	{/* );
}; */}

      {/* <div class="container">
        <div className="section-title">
          <h2 className="fw-bold text-primary text-uppercase">
            <p className="text-danger">
              Featu<span style={{ color: "purple" }}>res</span>{" "}
            </p>
          </h2>
        </div>
        <div className="row ms-5">
          <div className="col-lg-2 text-center mt-4" data-aos="fade-right"> */}
            {/* <img className="bd-placeholder-img rounded-circle zoom" width="50%" height="50%" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlSg86dG5rWq3ctmumotAeGaJHpo1Z9WFyow&usqp=CAU"
                role="img" alt="" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false" />

              <h2><strong classNameName='text-danger'>NBFC's</strong></h2>
              <p classNameName='text-dark'><b>"COEQUAL NIDHI LIMITED"</b>, is one of the most diversified deposit-
                taking NBFC's in India.</p>
              <p><a className="btn btn-secondary" href="#">View details &raquo;</a></p> */}
          {/* </div>
          <div className="col-lg-4 text-center mt-4" data-aos="fade-up">
            <img
              className="bd-placeholder-img rounded-circle zoom"
              width="50%"
              height="50%"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_YVqMXyJ3HgRHpWNHf7_CDKhFlwwLFKVNgw&usqp=CAU"
              role="img"
              alt=""
              aria-label="Placeholder: 140x140"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            />

            <h2>
              <strong classNameName="text-danger">SME's</strong>
            </h2>
            <p classNameName="text-dark">
              It accepts public and corporate deposits and offers a variety of
              financial services products to its customers.
            </p>
            <p>
              <a className="btn btn-secondary" href="#">
                View details &raquo;
              </a>
            </p>
          </div>
          <div className="col-lg-4 text-center mt-4 " data-aos="fade-left">
            <img
              className="bd-placeholder-img rounded-circle zoom"
              width="50%"
              height="50%"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlHdV6MpQD9wm9dyhgCHC4TyWATSzQb7PkcQ&usqp=CAU"
              role="img"
              alt=""
              aria-label="Placeholder: 140x140"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            />

            <h2>
              <strong classNameName="text-danger">LOANS</strong>
            </h2>
            <p classNameName="text-dark">
              Consumer Finance, SME Finance, Vehicle Finance, Personal Loan,
              Home and Gold Loan, E-Commerce, Commercial Lending.
            </p>
            <p>
              <a className="btn btn-secondary" href="#">
                View details &raquo;
              </a>
            </p>
          </div>
        </div>
      </div> */}
      {/* <button id="#mybutton">
        <a
          href="#"
          className="scroll-top d-flex align-items-right justify-content-center active"
        >
          <i id="#mybutton" class="fa-solid fa-arrow-up"></i>
        </a>
      </button> */}
      
      {/*<section  data-aos="fade-up" data-aos-duration="2000" id="aboutus" className='container offset-1 mb-2 border rounded shadow mt-5 ' >
<div className='row'>
    <div className='section-title position-relative pb-3 mx-auto'>
    <h2 className='text-primary'>
    <p style={{color:"purple"}} >OUR<span className='text-danger' > PLANS</span> </p>
    </h2>
    </div>
  </div>
<div  className='text-center' data-aos="fade-up"
     data-aos-duration="2000">
      <img src="coequlanidhi.jpeg" className='align-items-stretch order-1 order-lg-2 text-center img why img-fluid w-90 ' alt=""/>
    </div>
</section> */}

      {/* our services*/}
      {/* <section className='container-fluid'>
            <div className='section-title text-center position-relative'>
         <h2 className='text-center'><span class="text-danger">DEPOSIT</span><span style={{color: 'purple'}}> SCHEMES</span></h2>
        
          </div>
         <div class="owl-carousel owl-theme ps-3"  data-aos="fade-up">
    
    <div class="owl-item " >
        <div class="">
        <div class="display-block" >
              <Link class="dropdown-item text-dark" to="/recurringdp">
                <div class="box">
                  <img src="https://img.freepik.com/premium-vector/start-up-step-deposit-little-tree-growing-pile-coins-money-business-investment_228260-763.jpg" class="image-responsive  border border-1 border-warning shadow img-1 center-block" />
                  <div class="middle">
                    <h6 class="widget-title">RECURRING&nbsp;&nbsp;DEPOSIT </h6>
                  </div>
                </div>
              </Link>
            </div>
           
        </div>
    </div>
    <div class="owl-item">
        <div class="">
        <div class="display-block ">
              <Link class="dropdown-item a" to="/fixeddeposite" >
                <div class="box">
                  <img src="https://akm-img-a-in.tosshub.com/businesstoday/images/story/202303/fixed-deposit-sixteen_nine_2.jpg" class="image-responsive img-1  border border-1 border-warning shadow center-block" />
                  <div class="middle">
                    <h6 class="widget-title">FIXED&nbsp;&nbsp;DEPOSIT </h6>
                  </div>
                </div>
              </Link>
            </div>
        </div>
    </div>
    <div class="owl-item" >
        <div class="">
        <div class="display-block ">
              <Link class="dropdown-item  text-dark" to="/roi">
                <div class="box">
                  <img src="https://assets.entrepreneur.com/content/3x2/2000/20190304101808-ROI-bigstock--215096242.jpeg?format=pjeg&auto=webp&crop=16:9" class="image-responsive img-1 border border-1 border-warning shadow center-block" />
                  <div class="middle">
                    <h6 class="widget-title">RETURN&nbsp;&nbsp;ON&nbsp;&nbsp;INVESMENT</h6>
                  </div>
                </div>
              </Link>
            </div>
        </div>
    </div>
    <div class="owl-item">
        <div class="">
        <div class="display-block">
              <Link class="dropdown-item  text-dark" to="/kamadenu">
                <div class="box">
                  <img src="https://ankolaurbanbank.com/storage/app/media/new-2020/deposit/saving-deposit-infoimg.jpg" class="image-resposnive img-1 border border-1 border-warning shadow center-block" />
                  <div class="middle">
                    <h6 class="widget-title">KAMADHENU&nbsp;DAILY&nbsp;DEPOSIT&nbsp; </h6>
                  </div>
                </div>
              </Link>
            </div>
        </div>
    </div>
    <div class="owl-item">
        <div class="">
        <div class="display-block">
              <Link class="dropdown-item  text-dark" to="/kalpa">
                <div class="box">
                  <img src="https://5.imimg.com/data5/SELLER/Default/2020/12/KL/TG/YC/16238010/bharat-decorative-brass-kalpavriksha-tree-500x500.jpg" class="image-resposnive border border-1 border-warning shadow img-1 center-block" />
                  <div class="middle">
                    <h6 class="widget-title">KALPAVRUKSHA&nbsp;&nbsp;SCHEME </h6>
                  </div>
                </div>
              </Link>
            </div>
        </div>
    </div>
 
</div>
         </section> */}

      {/* <div className="vector mt-5 pt-3">
        <div className="middle-w3l container">
          <div
            className="section-title text-center position-relative  mx-auto"
            style={{ maxwidth: 600 }}
          >
            <h2 classNameName="text-center">
              <span style={{ color: "purple" }}>DEPOSIT</span>
              <span style={{ color: "red" }}> SCHEMES</span>
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 display-block ">
              <Link className="dropdown-item text-dark" to="/recurringdp">
                <div className="box shadow" style={{ borderRadius: '15px' }}>
                  <img
                    src="https://img.freepik.com/premium-vector/start-up-step-deposit-little-tree-growing-pile-coins-money-business-investment_228260-763.jpg"
                    // style={{ borderRadius: '35px' }}
                    className="image-responsive border border-1 border-warning shadow img-1" alt="recurring deposit"
                  />
                  <div className="middle">
                    <h6 className="widget-title">RECURRING DEPOSIT</h6>
                  </div>
                </div>
              </Link>
              <Link className="dropdown-item text-dark mt-4" to="/kamadenu">
                <div className="box shadow" style={{ borderRadius: '15px' }}>
                  <img
                    src="https://ankolaurbanbank.com/storage/app/media/new-2020/deposit/saving-deposit-infoimg.jpg"
                    // style={{ borderRadius: '35px' }}
                    className="image-responsive border border-1 border-warning shadow img-1" alt="Kamadhenu daily deposit scheme"
                  />
                  <div className="middle">
                    <h6 className="widget-title">KAMADHENU DAILY DEPOSIT SCHEME</h6>
                  </div>
                </div>
              </Link>
            </div>
            {/* <!-- Center Column -->
            <div className="col-lg-4 d-flex justify-content-center align-items-center">
              <Link className="dropdown-item a" to="/fixeddeposite">
                <div className="box shadow" style={{ borderRadius: '15px' }}>
                  <img
                    src="https://akm-img-a-in.tosshub.com/businesstoday/images/story/202303/fixed-deposit-sixteen_nine_2.jpg"
                    // style={{ borderRadius: '5px' }}
                    className="image-responsive border border-1 border-warning shadow img-1" alt="Fixed Deposit"
                  />
                  <div className="middle">
                    <h6 className="widget-title">FIXED DEPOSIT</h6>
                  </div>
                </div>
              </Link>
            </div>
             /* <!-- Second Column --> 
            <div className="col-lg-4 display-block">
              <Link className="dropdown-item text-dark" to="/roi">
                <div className="box shadow" style={{ borderRadius: '15px' }}>
                  <img
                    src="https://assets.entrepreneur.com/content/3x2/2000/20190304101808-ROI-bigstock--215096242.jpeg?format=pjeg&auto=webp&crop=16:9"
                    // style={{ borderRadius: '35px' }}
                    className="image-responsive border border-1 border-warning shadow img-1" alt="Return on investment"
                  />
                  <div className="middle">
                    <h6 className="widget-title">RETURN ON INVESTMENT</h6>
                  </div>
                </div>
              </Link>
              <Link className="dropdown-item text-dark mt-4" to="/kalpa">
                <div className="box shadow" style={{ borderRadius: '15px' }}>
                  <img
                    src="https://5.imimg.com/data5/SELLER/Default/2020/12/KL/TG/YC/16238010/bharat-decorative-brass-kalpavriksha-tree-500x500.jpg"
                    // style={{ borderRadius: '35px' }}
                    className="image-responsive border border-1 border-warning shadow img-1" alt="Kalpavruksha scheme"
                  />
                  <div className="middle">
                    <h6 className="widget-title">KALPAVRUKSHA SCHEME</h6>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="clearfix"> </div>
        </div>
      </div> */}
      <div>
        <Feature34 />
      </div>
      
      <div>
        <Service14 />
      </div>  

      {/*SERVICES */}
      {/* <div className="container p-2  mt-5 pt-5">
        <div
          className="section-title text-center position-relative pb-3  mx-auto"
          style={{ maxwidth: 600 }}
        >
          <h2 className="text-danger">
            CO
            <span style={{ color: "purple", fontFamily: "Trebuchet MS" }}>
              EQUAL
            </span>
            <span className="text-danger"> GROUP</span>
            <span style={{ color: "purple" }}> OF</span>
            <span className="text-danger"> COMPANIES</span>{" "}
          </h2>
        </div>

        <div className="row" data-aos="flip-up" data-aos-duration="1000">
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="img-box">
              <img
                className="service zoom"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_3abi6zC-V5jikKIG0VxsDOYyhvn1NZ3uSg&usqp=CAU"
                width="100%"
                height="150"
                alt=""
              />
              <h5 className="heading text-center">
                <a
                  href="https://www.coequalservices.com/technology.html"
                  className="text-decoration-none text-dark"
                >
                  COEQUAL SERVICES
                  <h6 className="text-center">
                    <a
                      className=" text-decoration-none"
                      href="https://www.coequalservices.com/"
                    >
                      <br />
                      www.coequalservices.com
                    </a>
                  </h6>
                </a>
              </h5>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="img-box">
              <img
                className="service zoom"
                src="http://www.coequalservices.com/img/Charity.jpeg"
                width="100%"
                height="150"
                alt=""
              />
              <h5 className="heading text-center ">
                <a
                  href="https://www.coequalservices.com/technology.html"
                  className="text-decoration-none text-dark"
                >
                  COEQUAL WELFARE <br />
                  FOUNDATION
                  <h6 className="text-center">
                    <a
                      className=" text-decoration-none"
                      href="https://www.coequalfoundation.com/"
                    >
                      www.coequalfoundation.com
                    </a>
                  </h6>
                </a>
              </h5>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 ">
            <div className="img-box">
              <img
                className="service zoom"
                src="http://www.coequalservices.com/img/technology.jpeg"
                width="100%"
                height="150"
                alt=""
              />
              <h5 className="heading text-center">
                <a
                  href="https://www.coequalservices.com/technology.html"
                  className="text-decoration-none text-dark"
                >
                  COEQUAL INFOTECH <br /> PRIVATE LIMITED
                  <h6 className="text-center">
                    <a
                      className=" text-decoration-none"
                      href="https://coequalinfotech.com/"
                    >
                      www.coequalinfotech.com
                    </a>
                  </h6>
                </a>
              </h5>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-12 ">
            <div className="img-box">
              <img
                className="service zoom"
                src="coequalnidhilimited.png"
                width="100%"
                height="150"
                alt=""
              />
              <h5 className="heading text-center">
                <a
                  href="https://www.coequalservices.com/technology.html"
                  className="text-decoration-none text-dark"
                >
                  COEQUAL NIDHI <br /> LIMITED
                  <h6 className="text-center">
                    <a
                      className=" text-decoration-none"
                      href="https://coequalnidhi.com/"
                    >
                      www.coequalnidhi.com
                    </a>
                  </h6>
                </a>
              </h5>
            </div>
          </div> */}
          {/*  <div class="col-lg-8 text-center" data-aos="zoom-in" data-aos-delay="150">
            <img className='text-center' src="1.jpeg" alt=""/>
</div>*/}
        {/* </div> */}
      {/* // </div> */}
      {/*testimonials */}
      <Swiperimage />
      <div className="elfsight-app-b8dedaca-991b-48a8-8c83-29b900588fb1"></div>
      <Md1 />
      {/*<Md/>*/}
      <Counter />
      <Flipper />

      {/* faq start 

     <section id="faq" className="faq section-bg">
      <div className="container-fluid">

        <div className="section-title">
          <h2><span classNameName='text-danger'>Frequently</span><span style={{color:'purple'}}> Asked </span><span classNameName='text-danger'> Questions</span></h2>
          <p>We've found the answers to your most frequently asked questions, from Gold Scheme, FD'S, ROI, Recurring Scheme etc.</p>
        </div>

        <div className="faq-list offset-2">
          <ul>
            <li data-aos="fade-up" classNameName='col-lg-12' data-aos-delay="100">
              <i className="fa fa-question-circle icon-help"></i> <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1"> How To Become A Memeber? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
                <p>
                 Just send a mail or  give a call to our number will contact soon!!
                </p>
              </div>
            </li>

            <li data-aos="fade-up" classNameName='col-lg-12' data-aos-delay="200">
              <i className="fa fa-question-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">How much is the initial FD AMOUNT? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                <p>
                  The minimum of of FD starts from 5000rs.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="300">
              <i className="fa fa-question-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">How many members are there in gold Scheme? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                <p>
                  In our 'COEQUAL NIDHI' more than 100 members are there!! 
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <i className="fa fa-question-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">What is RECURRING DEPOSIT PLAN? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                <p>
                  INVESMENTS STARTS FROM 100rs AND ABOVE .RATE OF INTEREST 4% TO 7%
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="500">
              <i className="fa fa-question-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">What is KALPAVRUKSHA SCHEME?<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                <p>
                  MINIMUM DEPOSIT  1000rs PER MONTH UPTO 7 YEARS AND PAYOUT WILL STARTS FROM 8TH YEAR. PAYOUT IS LIMITED TO NEXT 14 YEARS.
                </p>
              </div>
            </li>

          </ul>
        </div>

      </div>
    </section>  */}

      {/*our happy customer */}

      {/*<Career/> 
      <section className='container'>
        <div className='section-title'>
          <h2 className='text-center fw-bold  mt-3 text-primary text-uppercase'>
            <p style={{ color: "purple" }}>OUR HAPPY<span className='text-danger' > CUSTOMERS</span></p>

          </h2>
        </div>
        <div className='row pb-1' data-aos="flip-up" data-aos-duration="1000" >

          <div className='col-lg-3 zoom mt-2'>
            <ReactPlayer url="https://www.youtube.com/watch?v=IscZvef-T0M" width="250px" />
          </div>
          <div className='col-lg-3 text-center zoom mt-2'>
            <ReactPlayer url="https://www.youtube.com/watch?v=oZ0XM3KSM48" width="250px" />
          </div>
          <div className='col-lg-3  text-center zoom mt-2'>
            <ReactPlayer url="https://www.youtube.com/watch?v=0NnGcfbNdf8" width="250px" />
          </div>
          <div className='col-lg-3 text-center zoom mt-2'>
            <ReactPlayer url="https://www.youtube.com/shorts/cJIhvp8-Cso" width="250px" />
          </div>

        </div>

      </section>*/}
      
      <SideIconBar />

      <ContactOne />
      <Footer />
    </>
  );
};
export default Body;
