import React from 'react';
import {Link} from 'react-router-dom';
import Header from './header';
import Header1 from './header1';
const Project=()=>{
    return(
        <>
                <Header1/>
             <div className='container-fluid w-100 mt-3 '>
                <p className='text-center text-primary fs-1 text-uppercase'><b>OUR PLANS<hr style={{}}/></b></p>
            <div className='row m-4 p-4'>
            <div className='col-lg-12'>
                <img src="1.png" width="100%"/>
            </div>
            </div>
            <div className='row m-3 p-4 '>
            <div className='col-lg-12'>
                <img src="2.png" width="100%"/>
            </div>
            </div>
            <div className='row m-3 p-4'>
            <div className='col-lg-12'>
                <img src="3.png" width="100%"/>
            </div>
            </div>
            <div className='row m-3 p-4'>
            <div className='col-lg-12'>
                <img src="4.png" width="100%"/>
            </div>
            </div>
        </div>
        </>
    )
};
export default Project;