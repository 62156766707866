import React from 'react';
import Header1 from './header1';

const Roiprivacy = () => {
  return (
    <>
      <Header1 />
      <div className="container-fluid py-5 mt-3 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-20 col-md-20">
              <div className="mt-5">
              <h1 className="mb-4 text-center text-danger" style={{ marginTop: -40 }}>
                RETURN ON INVESTMENT (ROI)
              </h1>
              <h3 className="text-bold">TERMS AND CONDITIONS</h3>

              <ol>
                <li>
                  <p>
                    <span className="text-bold">COEQUAL SERVICES</span> offers its investors Return on Investment Schemes which offer a precise gauge of the effectiveness and success of an investment that enables the investors to evaluate the efficiency with which their capital has been used and the acceptability of the returns produced.
                  </p>
                </li>

                <li>
                  <p>
                    The scheme offers several contrasting investment options which are categorized as per the interest provided that shall vary as per the scheme and the pay scale that is to be invested by the investor as elaborated in the brochure and the company guides and portals of <span className="text-bold">COEQUAL SERVICES</span>.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="text-bold">Tenure:</span> The tenure of the deposit will be for a period of 1 year or 12 months. Subject to change anytime.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="text-bold">Tax Deducted at Source –</span> ROI interest income is subject to TDS.
                  </p>
                </li>

                <li>
                  <p>
                    These Return on Investment Schemes are provided to the investors as per their needs on the basis of 3 types i.e. Type 1, Type 2, Type 3 as detailed in the sub-clauses below:
                  </p>
                </li>
              </ol>
              <h4 className="mb-4">Types of Investment</h4>
                <ol style={{ listStyleType: 'lower-roman' }}>
                  <li>
                    <p>
                      <span className="text-bold">Type 1:</span> The investors shall be provided with the investment amount along with the interest amount (as per the investment option chosen by the investor) that shall be provided every month. The amount (invested amount & interest) credited per annum shall be divided equally for all 12 months. This type is categorized as <span className="text-bold">PADMA, PARIJATA, CHAMPAKA</span> depending on the interest rates provided and the investment to be made by the investor.
                    </p>
                  </li>

                  <li>
                    <p>
                      <span className="text-bold">Type 2:</span> The Company shall keep the principal amount with itself, and the interest amount provided as per the scheme shall be credited to the customer every quarter. This type is categorized as <span className="text-bold">MANDARA, SIRISA, JAPA</span> depending on the interest rates provided and the investment to be made by the investor.
                    </p>
                  </li>

                  <li>
                    <p>
                      <span className="text-bold">Type 3:</span> The investor shall enter into a valid 11-month contract with <span className="text-bold">COEQUAL SERVICES</span>, wherein the principal amount invested by the investor shall remain with the company, and the <span className="text-bold">REPAYMENT</span> shall commence from the next month of signing the contract, i.e., up to 10 months only. This type is categorized as <span className="text-bold">ASHVATA, KADAMBA, CHANDANA</span>.
                    </p>
                  </li>
                </ol>

                <p>
                  <span className="text-bold">&#10070; Please note:</span> Type 3 shall be subjected to additional charges that the investor shall be liable to pay while paying the Principal Amount. These include service charges and processing fees, which shall be deducted from the interest every month.
                </p>
                <ol start="4" style={{ listStyleType: 'lower-roman' }}>
                  <li>
                    <p>The principal amount shall however be returned to the customer only after completion of one tenure.</p>
                  </li>
                </ol>

                <h4 className="mb-4">Interest Rates</h4>
                <p>The interest rates provided by the scheme are as per and in accordance with the guidelines provided by the Reserve Bank of India.</p>

                <h4 className="mb-4">Criteria for Joining the Scheme</h4>
                <p>
                  Any investor who is willing to invest themselves in the scheme needs to furnish their KYC details to <span className="text-bold">COEQUAL SERVICES</span>, which are detailed in the sub-clause.
                </p>
                <p>
                  A valid Aadhaar card and PAN card are the required mandates for registering themselves to the scheme.
                </p>

                <h4 className="mb-4">Cancellation/Withdrawal from the Scheme</h4>
                <p>
                  In case the customer chooses to withdraw from the scheme before the completion of tenure, the customer shall be entitled to receive only the principal amount along with 1% or 2% rate of interest per annum as per the company’s discretion.
                </p>

                <h4 className="mb-4">Compliance with Laws</h4>
                <p>
                  <span className="text-bold">COEQUAL SERVICES</span> and its affiliates may take any action in accordance with or in order to comply with any applicable laws (including the laws, regulations, notices, and directions of public and regulatory authorities) which relate to the prevention of money laundering, terrorist financing, and the provision of financial and other services to any persons or entities as it deems appropriate.
                </p>
                <h4 className="mb-4">Payment</h4>
                <p>
                  All payments will be made to the investor's account or any other account as determined by COEQUAL SERVICES.
                </p>

                <h4 className="mb-4">Investor Responsibility</h4>
                <p>
                &nbsp;&nbsp;&nbsp;Investors are responsible for their own investment decisions. COEQUAL SERVICES is not liable for losses or claims related to investments made with the company. All decisions are at the investor’s own risk and discretion.
                </p>

                <h4 className="mb-4">Governing Law and Jurisdiction</h4>
                <p>
                The Investment Terms and all relations between the parties shall be governed by and construed in accordance with the laws of India. Any legal action or proceedings in connection with the Investment Terms, or any dispute thereunder, may be brought in the courts of Bangalore,  Karnataka in India and investor irrevocably submits to the jurisdiction of Indian courts. The COEQUAL SERVICES reserves the right to institute proceedings in the investor’s domicile or in any other appropriate jurisdiction. All the petitions/ files shall happen or shall be submitted only in English version. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roiprivacy;
