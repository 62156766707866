import React from 'react';
import {Link} from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Header1 from './header1';

const FixedDeposit=()=>{
return(
  <>
   <Header1/>
  
 <div className='text-center mt-5 pt-5'>
	<div className='centercontain'style={{fontFamily: "Raleway"-"sans-serif", fontWeight: "900", fontStyle: "medium"}} >
	<h1 className='fix' style={{color:'purple'}}>FIXED DEPOSIT</h1>
    <img className='' width="100%" height="500px" src="https://i.ibb.co/yhdqGLx/De-Watermark-ai-1723109246657.png" alt=""/>
	</div>
 </div>
 <div class="container">
	<div class="row mt-5" style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}} >
			<div class="col-lg-8 inner">
			
					<h3><span className='text-primary'>FIXED</span><span style={{color:"purple"}}> DEPOSIT</span></h3>
					<p>It is a scheme where a member makes a onetime investment for a fixed tenure and gets a higher rate of interest.
<br/>
Investments Starts From ₹ 5000 & Above.<br/>
Rate of Interest 8.50% To 12%.
					</p>
				
			</div>
			<div class="col-lg-4">
				<img src="https://kj1bcdn.b-cdn.net/media/81434/fixed-deposit-1.jpg?width=1200" alt="" className='img-responsive img-fluid' style={{ borderRadius: '15px'}} /> 
			</div>
			
	</div>
	<div class="row mt-5">
		<div class="container">
			<div class="middle" style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}} >
				<div class="table-responsive">
				  <table class="table table-bordered" style={{backgroundimage: "url('../h1.jpeg')", backgroundrepeat: "no-repeat", backgroundposition: "center", backgroundSize: "contain", opacity: "1"}}>
				    <thead className='bg-primary'>
				      <tr>
				        <th>Amount</th>
				        <th>1 Year</th>
				        <th>2 Year</th>
				        <th>3 Year</th>
				        <th>4 Year </th>
				        <th>5 Year </th>
				        <th>6 Year </th>
				        <th>7 Year </th>
				        <th>10 Year </th>

				      </tr>
				    </thead>
				    <tbody>
						<tr>
						<td><b>Interest %</b></td>
						<td><b>8.50%</b></td>
						<td><b>9%</b></td>
						<td><b>9.50%</b></td>
						<td><b>10%</b></td>
						<td><b>10.50%</b></td>
						<td><b>11%</b></td>
						<td><b>11.50%</b></td>
						<td><b>12%</b></td>

						</tr>
						
				      <tr>
				        <td>5000</td>
				        <td>425</td>
				        <td>450</td>
				        <td>475</td>
				        <td>500</td>
				        <td>525</td>
				        <td>550</td>
				        <td>575</td>
				        <td>600</td>


				      </tr>
				      <tr>
				        <td>10000</td>
				        <td>850</td>
				        <td>900</td>
				        <td>950</td>
				        <td>1000</td>
				        <td>1050</td>
				        <td>1100</td>
				        <td>1150</td>
				        <td>1200</td>
				        
				      </tr>
				      <tr>
				        <td>20000</td>
				        <td>1700</td>
				        <td>1800</td>
				        <td>1900</td>
				        <td>2000</td>
				        <td>2100</td>
				        <td>2200</td>
				        <td>2300</td>
				        <td>2400</td>
				      

				      </tr>
				      <tr>
				        <td>30000</td>
				        <td>2550</td>
				        <td>2700</td>
				        <td>2850</td>
				        <td>3000</td>
				        <td>3150</td>
				        <td>3300</td>
				        <td>3450</td>
				        <td>3600</td>


				      </tr>
				      <tr>
				        <td>40000</td>
				        <td>3400</td>
				        <td>3600</td>
				        <td>3800</td>
				        <td>4000</td>
				        <td>4200</td>
				        <td>4400</td>
				        <td>4600</td>
				        <td>4800</td>

				      </tr>
				      <tr>
				        <td>500000 & Above</td>
				        <td>42500</td>
				        <td>45000</td>
				        <td>47500</td>
				        <td>50000</td>
				        <td>52500</td>
				        <td>55000</td>
				        <td>57500</td>
				        <td>60000</td>


				      </tr>
				    </tbody>
				
				  </table>
				<i className='text-danger' style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}} >* Senior Citizens and Physically Challenged can enjoy an increment of 0.5%  additional rate of interest on the existing one.</i>
				</div>
				
			</div>
		</div>
	</div>
	<div class="row  mb-2 mt-3">
		<div class="container">
			<div class="col-md-12" style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}} >
				<div class="inner mt-2">
					<h3 style={{color:"purple"}}>Features<span> And Benefits</span></h3>
					<p><i class="fa fa-angle-double-right"></i> Assured and high Rate of Interest.</p>
					<p><i class="fa fa-angle-double-right"></i> Flexible tenure.</p>
					<p><i class="fa fa-angle-double-right"></i> Accident death compensation<br/>
&nbsp;&nbsp;&nbsp;&nbsp;FD(4,5,6 & 7 Years)- 1 Lakh.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;FD(10 Years)- 2 lakh.</p>
					<p><i class="fa fa-angle-double-right"></i>	As per the Member requirement interest can be deposited on quarterly basis as well if opted for at the time of Deposit.</p>
					
					<p><i class="fa fa-angle-double-right"></i>	In case of withdrawal before tenure completion the member will get a fixedRate of Interest 8.50% to 12%  irrespective of the scheme he has opted for.</p> 
				</div>
			</div>
		</div>		
	</div>
</div>
    
    <Footer/>
    </>
)
};
export default FixedDeposit;