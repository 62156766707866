import React from 'react';
import {Link} from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Header1 from './header1';

const Kalpa=()=>{
return(
  <>
   <Header1/>
   <div className='text-center mt-5 pt-5'>
	<div className='centercontain'>
	{/*<h1 className='fix text-danger'>RETURN ON INVESMENT</h1> */}
    <img className='' width="100%" height="500px" src="https://cdnblog.etmoney.com/wp-content/uploads/2020/08/Benefits-Of-Fixed-Deposit.jpg" alt=""/>
	</div>
 </div>
 <div class="container">
	<div class="row mt-5">
			<div class="col-lg-8 inner" style={{fontFamily: "Gupter", fontWeight: "700", fontStyle: "normal"}}>
			
					<h3><span className='text-primary'>KALPAVR</span><span style={{color:"purple"}}>UKSHA</span></h3>
					<p>
                  
					</p>
				
				
			</div>
			<div class="col-lg-4">
				<img src="https://www.bankalfalah.com/wp-content/uploads/2018/12/Term-Deposit-horizontal-1.jpg" alt="" className='img-responsive img-fluid'/> 
			</div>
			
	</div>
	<div class="row mt-5">
		<div class="container">
			<div class="middle" style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}}>
				<div class="table-responsive">
				  <table class="table table-bordered">
				    <thead className='bg-primary'>
				      <tr>
				        <th>No.</th>
				        <th>Deposit Amount Yearly</th>
				        <th>Pay out After 7th Year</th>
				        <th>Surrender Benefits Yearly</th>
				       
				      </tr>
				    </thead>
				    <tbody>
				      <tr>
				        <td>1</td>
				        <td>12000</td>
				        <td>-</td>
				        <td>-</td>
				      
				      </tr>
				      <tr>
					  <td>2</td>
				        <td>12000</td>
				        <td>-</td>
				        <td>-</td>
				      </tr>
				      <tr>
					  <td>3</td>
				        <td>12000</td>
				        <td>-</td>
				        <td>-</td>
				      </tr>
				      <tr>
					  <td>4</td>
				        <td>12000</td>
				        <td>-</td>
				        <td>-</td>
				      </tr>
				      <tr>
					  <td>5</td>
				        <td>12000</td>
				        <td>-</td>
				        <td>-</td>
				      </tr>
				      <tr>
				      <td>6</td>
				        <td>12000</td>
				        <td>-</td>
				        <td>-</td>
				      </tr>
					  <tr>
				      <td>7</td>
				        <td>12000</td>
				        <td>-</td>
				        <td>-</td>
				      </tr>
					  <tr>
				      <td>8</td>
				        <td>-</td>
				        <td>12000</td>
				        <td>100000</td>
				      </tr>
					  <tr>
				      <td>9</td>
				        <td>-</td>
				        <td>12000</td>
				        <td>100000</td>
				      </tr>
					  <tr>
				      <td>10</td>
				        <td>-</td>
				        <td>12000</td>
				        <td>100000</td>
				      </tr>
					  <tr>
				      <td>11</td>
				        <td>-</td>
				        <td>12000</td>
				        <td>100000</td>
				      </tr>
					  <tr>
				      <td>12</td>
				        <td>-</td>
				        <td>12000</td>
				        <td>100000</td>
				      </tr>
					  <tr>
				      <td>13</td>
				        <td>-</td>
				        <td>12000</td>
				        <td>100000</td>
				      </tr>
					  <tr>
				      <td>14</td>
				        <td>-</td>
				        <td>12000</td>
				        <td>100000</td>
				      </tr>
					  <tr>
				      <td>15</td>
				        <td>-</td>
				        <td>12000</td>
				        <td>100000</td>
				      </tr>
					  <tr>
				      <td>16</td>
				        <td>-</td>
				        <td>12000</td>
				        <td>100000</td>
				      </tr>
					  <tr>
				      <td>17</td>
				        <td>-</td>
				        <td>12000</td>
				        <td>100000</td>
				      </tr>
					  <tr>
				      <td>18</td>
				        <td>-</td>
				        <td>12000</td>
				        <td>100000</td>
				      </tr>
				    </tbody>
				  </table>
				</div>
			</div>
		</div>
	</div>
	<div class="row  mb-2 mt-2">
		<div class="container">
			<div class="col-md-12"  style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}}>
				<div class="inner mt-2">
					<h3 style={{color:"purple"}}>Features<span> And Benefits</span></h3>
					<p><i class="fa fa-angle-double-right"></i> Minimum deposit of Rs.1000/- per month.</p>
					<p><i class="fa fa-angle-double-right"></i> Deposits to be done for 84 months(7 Years).</p>
					<p><i class="fa fa-angle-double-right"></i>	Payout will start from 8th year and payout is limited to next 14 years.</p>
					<p><i class="fa fa-angle-double-right"></i> No prematurity is allowed in Kalpavruksha scheme.</p>
					<p><i class="fa fa-angle-double-right"></i> KYC Documents of members are compulsory for joining Kalpavruksha Scheme.</p>
					<p><i class="fa fa-angle-double-right"></i>	Discontinued account will get return after the end of paying term(7 years) with savings
 interest rates.</p> 
 <p><i class="fa fa-angle-double-right"></i> Deposit instalments can be made Monthly, Quarterly, Half Yearly and Yearly, and the payout
       instalments will be same as the deposit instalment.</p>
				</div>
			</div>
		</div>		
	</div>
    </div>
    <Footer/>
    </>
)
};
export default Kalpa;