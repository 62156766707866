import React from "react";
import Header from "./header";
import { Link } from "react-router-dom";
import Register from "./register";
import Header1 from "./header1";
const Login=()=>{
return(
<>
<Header1/>

<div className="container-fluid loginimg W-100  ">

<div className="col-lg-8 text-center mt-5">
<div className="card border-primary p-4 w-50 offset-6  rounded shadow ">
<h4 className=" text-center ">LOGIN</h4>

<input className="form-control m-3" placeholder="Enter the Username" id="email" name="email" type="text"/>
<input type="text" className="form-control m-3" placeholder="Enter the Password" />
<div className="row">
    <div className="col-lg-6">
    <Link className="">Remember me</Link>
    </div>
    <div className="col-lg-6">
<Link className="text-primary">Forget Password?</Link>
    </div>
</div>
<div className="text-center">
<button className="btn bg-primary rounded mt-3 mb-2">LOGIN</button>
</div>
<p>Dont have an account?<Link to="register"> Sign up</Link></p>
<p className="p">connect with us</p>
<div className="inline-block">
    <i className="fa fa-facebook  i m-2"></i>
    <i className="fa fa-google i m-2"></i>
    <i className="fa fa-github i m-2"></i>
</div>
</div>
</div>

    
   




</div>
</>
)
};
export default Login;