import React from 'react';
import {Link} from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Header1 from './header1';
// import Roiprivacy from './roiprivacy';

const Roi=()=>{
return(
  <>
  <Header1/>
  <div className='text-center mt-5 pt-5'>
	<div className='centercontain'>
	{/*<h1 className='fix text-danger'>RETURN ON INVESMENT</h1> */}
    <img className='' width="100%" height="500px"  src="https://assets.entrepreneur.com/content/3x2/2000/20190304101808-ROI-bigstock--215096242.jpeg?format=pjeg&auto=webp&crop=16:9" alt=""/>
	</div>
 </div>
 <div class="container">
	<div class="row mt-5">
			<div class="col-lg-8 inner" style={{fontFamily: "Merriweather", fontWeight: "900", fontStyle: "normal"}}>
			
					<h3><span className='text-primary'>RETURN ON</span><span style={{color:"purple"}}> INVESMENT</span></h3>
					<p style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}}>
                    It is a deposit scheme in which members with fixed or regular incomes , deposit a fixed amount every month in their Recurring Deposit Account.
					</p>
				
				
			</div>
			<div class="col-lg-4">
				<img src="https://tworoadsco.com/assets/images/mathieu-stern-1zO4O3Z0UJA-unsplash.jpg" alt="" className='img-responsive img-fluid'/> 
			</div>
			
	</div>
	<div class="row mt-5">
		<div class="container">
			<div class="middle" style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}}>
				<div class="table-responsive">
				<table class="table table-bordered">
				    <thead className='bg-primary'>
				      <tr>
						<th>SCHEME I (Yearly)</th>
						<th>Schemes</th>
				        <th>Investment Amount</th>
				        <th>Interest (Yearly)</th>
				    
				      </tr>
				    </thead>
					<tbody>
					  <tr>
						<td></td>
						<td>Padma Scheme</td>
						<td>5,000-3,00,000</td>
						<td>6%</td>
					  </tr>
				      <tr>
						<td></td>
				        <td>Parijatha Scheme</td>
				        <td>3,00,000-6,00,000</td>
				        <td>7%</td>
				       
				      </tr>
					  <tr>
						<td></td>
				        <td>Champaka Scheme</td>
				        <td>6,00,000-10,00,000</td>
				        <td>8%</td>
				       
				      </tr>
					
				      <tr >
						<th>SCHEME II (Quaterly)</th>
						<th>Schemes</th>
				        <th>Investment Amount</th>
				        <th>Interest (Quaterly)</th>
				       
				       
				      </tr>
					  <tr class=" table">
						<td></td>
						<td>Mandara Scheme</td>
						<td>5,000-3,00,000</td>
						<td>6%</td>
					  </tr>
				      <tr>
						<td></td>
				        <td>Sirisa Scheme</td>
				        <td>3,00,000-6,00,000</td>
				        <td>7%</td>
				       
				      </tr>
					  <tr>
						<td></td>
				        <td>Japa Scheme</td>
				        <td>6,00,000-10,00,000</td>
				        <td>8%</td>
				       
				      </tr>
				
				      <tr >
						<th>SCHEME III (Monthly)</th>
						<th>Schemes</th>
				        <th>Investment Amount</th>
				        <th>Interest (Monthly)</th>
				       
				       
				      </tr>
					  <tr class="table">
						<td></td>
						<td>Ashvatha Scheme</td>
						<td>5,000-3,00,000</td>
						<td>10%</td>
					  </tr>
				      <tr>
						<td></td>
				        <td>Kadamba Scheme</td>
				        <td>3,00,000-6,00,000</td>
				        <td>7.5%</td>
				       
				      </tr>
					  <tr>
						<td></td>
				        <td>Chandana Scheme</td>
				        <td>6,00,000-10,00,000</td>
				        <td>5%</td>
				       
				      </tr>
				
				    </tbody>
				  </table>
				</div>
			</div>
		</div>
	</div>
	<div class="row  mb-2 mt-2">
		<div class="container">
			<div class="col-md-12" style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}}>
				<div class="inner mt-2">
					<h3 style={{color:"purple"}}>Terms And Conditions<span> Of Scheme III</span></h3>
					<p><i class="fa fa-angle-double-right"></i> Minimum Deposit Of 5,000/- & Minimum 10,00,000.</p>
					<p><i class="fa fa-angle-double-right"></i> Deposit To Be Done for 1 Year.</p>
					<p><i class="fa fa-angle-double-right"></i>	Payout will Start From the Following Month of Deposit To Next 10 Months.</p>
					<p><i class="fa fa-angle-double-right"></i>	TDS, Service Charge, Processing Fee Will Be Deducted From the Interest Amount Every Month.</p>
					<div class="copyright text-center mt-3">
						<Link className="text-dark" to="/roiprivacy"> <h6>Our Terms & Conditions
					</h6></Link>
					</div>
					
					
				</div>
			</div>
		</div>		
	</div>
</div>
    <Footer/>
    </>
)
};
export default Roi;