import React from 'react';
import { Link } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Header1 from './header1';
const Contact=()=>{
    return(
        <>
        <Header1/>
        
        <section className='mt-2'>

<div  className='container shadow mt-5' >

  <div className='contactinfo' >
    <div >
      <h2 className='text-center'>Contact Info</h2>
      <ul className='info' >
        <li>
          <span><img src="https://www.freeiconspng.com/thumbs/address-icon/address-icon-15.png"/></span>
          <span> #101, A' Block, Sarovar Mansion<br/>
          1st Floor, Dodda Banaswadi Main Road,
          Annaiah Reddy Layout,<br/> 
          Banswadi, Bengalore,<br/>
          Karnataka 560-043
          </span>
        </li>
        <li>
          <span><img src="https://cdn.pixabay.com/photo/2016/06/13/17/30/mail-1454731__340.png"/></span>
          <span>support@coequalnidhi.com
          </span>
        </li>
        <li>
          <span><img src="https://cdn-icons-png.flaticon.com/512/455/455705.png"/></span>
          <span>+91-80-29909931/29909932
          </span>
        </li> 
        <li>
          <span><img src="https://cdn-icons-png.flaticon.com/512/72/72626.png"/> </span>
          <span>www.coequalnidhi.com
          </span>
        </li>
      </ul>
      <ul className='sci ms-3'>
        <li><a href="https://www.facebook.com/coequal.nidhi.limited"><i className='fa fa-facebook text-white'></i></a></li>
        <li><a href="https://twitter.com/COEQUALNIDHI"><i className='fa fa-twitter text-white'></i></a></li>
        <li><a href="https://www.linkedin.com/feed/?trk=sem-ga_campid.14650114788_asid.127961666300_crid.651859540485_kw.linkedin%20login_d.c_tid.kwd-12704335873_n.g_mt.e_geo.9062033"><i className='fa fa-linkedin text-white'></i></a></li>
        <li><a href="https://www.youtube.com/channel/UCT2D6b3Gs-t93Kms3IyfIQA"><i className='fa fa-youtube text-white'></i></a></li>
        <li><a href="https://www.instagram.com/coequalnidhilimited/"><i className='fa fa-instagram text-white'></i></a></li>
      </ul>
    </div>
  </div>
  <form action="https://formsubmit.co/support@coequalnidhi.com" method="POST" >
  <div className='contactForm' >
 
    <h3>Send a Message</h3>
    <div className='formBox'>
      <div className='inputBox w50'>
        <input type="text" name="" required/>
        <span>First Name</span>
      </div>
      <div className='inputBox w50'>
        <input type="text" name="" required/>
        <span>Last Name</span>
      </div>
      <div className='inputBox w50'>
        <input type="text" name="" required/>
        <span>Email Address</span>
      </div>
      <div className='inputBox w50'>
        <input type="text" name="" required/>
        <span>Mobile Number</span>
      </div>
      <div className='inputBox w100'>
        <textarea name="" required></textarea>
        <span>Write your message here...</span>
      </div>  
      
      <div className='inputBox w100'>
        <input type="submit" value="send"/>
      </div>
    </div>
  </div>
  </form>
</div>
</section>
<Footer/>
        </>
    )
};
export default Contact;