import React from 'react';
import './sideiconbar.css';

const SideIconBar = () => {
  return (
    <div className="redes">
      <a className="instagram" href="https://www.instagram.com/coequalnidhilimited/" target="_blank" rel="noopener noreferrer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 550 550"
          fillRule="nonzero"
        >
          <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
        </svg>
      </a>
      <a className="twitter" href="https://twitter.com/COEQUALNIDHI" target="_blank" rel="noopener noreferrer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 564 564"
        >
          <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path>
        </svg>
      </a>
      <a className="facebook" href="https://www.facebook.com/coequal.nidhi.limited" target="_blank" rel="noopener noreferrer">
        <svg
          viewBox="0 0 50 50"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M40,0H10C4.486,0,0,4.486,0,10v30c0,5.514,4.486,10,10,10h30c5.514,0,10-4.486,10-10V10C50,4.486,45.514,0,40,0z M39,17h-3 c-2.145,0-3,0.504-3,2v3h6l-1,6h-5v20h-7V28h-3v-6h3v-3c0-4.677,1.581-8,7-8c2.902,0,6,1,6,1V17z"></path>
        </svg>
      </a>
      <a className="linkedin" href="https://www.linkedin.com/feed/?trk=sem-ga_campid.14650114788_asid.127961666300_crid.651859540485_kw.linkedin%20login_d.c_tid.kwd-12704335873_n.g_mt.e_geo.9062033" target="_blank" rel="noopener noreferrer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M22.23 0H1.77C.79 0 0 .77 0 1.72v20.54C0 23.23.79 24 1.77 24h20.46c.98 0 1.77-.77 1.77-1.74V1.72C24 .77 23.21 0 22.23 0zM7.19 20.45H3.56V9.02h3.63v11.43zM5.37 7.64c-1.16 0-2.1-.95-2.1-2.1 0-1.16.94-2.1 2.1-2.1 1.16 0 2.1.94 2.1 2.1 0 1.15-.95 2.1-2.1 2.1zM20.45 20.45h-3.63v-5.59c0-1.33-.03-3.05-1.85-3.05-1.85 0-2.14 1.45-2.14 2.95v5.69h-3.63V9.02h3.48v1.56h.05c.49-.93 1.69-1.91 3.49-1.91 3.73 0 4.42 2.45 4.42 5.63v6.14z"/>
        </svg>
      </a>
      <a className="youtube" href="https://www.youtube.com/channel/UCT2D6b3Gs-t93Kms3IyfIQA" target="_blank" rel="noopener noreferrer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          fill="#FFFFFF"
        >
          <path d="M43.6 13.4c-.5-1.8-2-3.3-3.8-3.8C36.6 8 24 8 24 8s-12.6 0-15.8.6c-1.8.5-3.3 2-3.8 3.8C4 16.6 4 24 4 24s0 7.4.4 10.6c.5 1.8 2 3.3 3.8 3.8 3.2.6 15.8.6 15.8.6s12.6 0 15.8-.6c1.8-.5 3.3-2 3.8-3.8.4-3.2.4-10.6.4-10.6s0-7.4-.4-10.6zM19.2 29.9V18.1l10.4 5.9-10.4 5.9z"/>
        </svg>
      </a>
    </div>
  );
};

export default SideIconBar;
