import React from 'react';
import {Link} from 'react-router-dom';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import Header from './header';
import Header1 from './header1';

export default function App() {
  return (
<>
<Header1/>

    
    <section className='container-fluid border border-primary rounded m-2 p-2'>
        <div className='position-relative pb-3 mb-5 mx-auto' style={{width:600}}>
          <h1 className='mb-0 mt-3 text-center'>OUR GALLERY<hr/></h1>
          </div>
    <MDBRow>
      {/* <MDBCol lg={4} md={12} className='mb-4 mt-2 mb-lg-0'>
        <img
          src='IMG-20230502-WA0132.jpg'
          className='w-100 shadow-1-strong rounded mb-4 zoom box'
          alt='Boat on Calm Water'
        />

        <img
          src='IMG-20230502-WA0125.jpg'
          className='w-100 shadow-1-strong rounded mb-4 box zoom'
          alt='Wintry Mountain Landscape'
        />
      </MDBCol> */}

      {/* <MDBCol lg={4} className='mb-4 mb-lg-0'>
        <img
          src='IMG-20230502-WA0106.jpg'
          className='w-100 shadow-1-strong rounded mb-4 box zoom'
          alt='Mountains in the Clouds'
        />

        <img
          src='IMG-20230502-WA0101.jpg'
          className='w-100 shadow-1-strong rounded mb-4 box zoom'
          alt='Boat on Calm Water'
        />
      </MDBCol> */}

      {/* <MDBCol lg={4} className='mb-4 mb-lg-0'>
        <img
          src='IMG-20230502-WA0094.jpg'
          className='w-100 shadow-1-strong rounded mb-4 box zoom'
          alt='Waves at Sea'
        />

        <img
          src='IMG-20230502-WA0038.jpg'
          className='w-100 shadow-1-strong rounded mb-4  box zoom'
          alt='Yosemite National Park'
        />
      </MDBCol> */}

      <MDBCol lg={4} md={12} className='mb-4 mt-2 mb-lg-0'>
        <img
          src='IMG-20230330-WA0006.jpg'
          className='w-100 shadow-1-strong rounded mb-4 zoom box'
          alt='Boat on Calm Water'
        />

        <img
          src='IMG-20230116-WA0033.jpg'
          className='w-100 shadow-1-strong rounded mb-4 box zoom'
          alt='Wintry Mountain Landscape'
        />
      </MDBCol>

      <MDBCol lg={4} className='mb-4 mb-lg-0'>
        <img
          src='IMG-20221224-WA0021.jpg'
          className='w-100 shadow-1-strong rounded mb-4 box zoom'
          alt='Mountains in the Clouds'
        />

        <img
          src='IMG-20221224-WA0013.jpg'
          className='w-100 shadow-1-strong rounded mb-4 box zoom'
          alt='Boat on Calm Water'
        />
      </MDBCol>

      <MDBCol lg={4} className='mb-4 mb-lg-0'>
        <img
          src='IMG-20221223-WA0047.jpg'
          className='w-100 shadow-1-strong rounded mb-4 box zoom'
          alt='Waves at Sea'
        />

        <img
          src='IMG_20230106_151614.jpg'
          className='w-100 shadow-1-strong rounded mb-4  box zoom'
          alt='Yosemite National Park'
        />
      </MDBCol>

    </MDBRow>
    </section>
    </>
  );
}