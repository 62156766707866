import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';
import Review from './Review';

class SimpleForm extends Component {
    render() {
      return (
        <ChatBot
          steps={
            [
              {
                id: '0',
                message: 'Hi Welcome to COEQUAL NIDHI LIMITED!',
                // This calls the next id
                // i.e. id 1 in this case
                trigger: 'member',
              },
            
              {
                id: "member",
                message: 'Be A Member Of COEQUAL NIDHI LIMITED👏',
                trigger: 'member-option',
              },
              {
                id: 'member-option',
                options: [
                  { value: 'y', label: 'Yes', trigger: 'yes-response' },
                  { value: 'n', label: 'No', trigger: 'no-response' },
                ]
              },
              {
                id: 'yes-response',
                message: 'Great! Please Enter your Mobile Number😊',
                trigger: 'user',
              },
              {
                id: 'user',
                user: true,
                trigger: 7,
                validator: (value) => {
                  if (isNaN(value)) {
                    return 'value must be a number';
                  } else if (value < 0) {
                    return 'Enter the correct Mobile Number';
                  } 
            
                  return true;
                },
              },
              {
                id: 'no-response',
                message: 'Sorry to hear this.',
                trigger: 'askname'
              },
            
            {
            id:'askname',
            message:"Can i know your Name please...",
            trigger:'name'
            },
            {
            id:'name',
            user:true,
            trigger:'greet'
            },
            {
            id:"greet",
            message:"Hello {previousValue}! how can we help you?? ",
            trigger: 3,
            },
              {
                id: '3',
                message: "What is the issue you are facing?",
                trigger: 4,
              },
              {
                id: '4',
                user: true,
                trigger: '1',
              },
              {
                id: '1',
                message: "Can You Please enter your mobile number 📱, So we can contact you",
                trigger: 'mob'
              },
              {
                id: 'mob',
                user: true,
                trigger: '7',
                validator: (value) => {
                  if (isNaN(value)) {
                    return 'value must be a number';
                  } else if (value < 0) {
                    return 'Enter the correct Mobile Number';
                  } 
            
                  return true;
                },
              },
            
              {
                id: '7',
                message:'Thank you..Will contact you soon...',
                trigger:'last'
              },
            {
              id:'last',
              user:true,
              trigger:'end'
            },
            {
              id:'end',
              message:'Try to ask something else or Please contact Us😊'
            }
                
                //  end: true,
              
            
            ]}
            
            
            
            
        />
      );
    }
  }
  
  export default SimpleForm;