import React from 'react';
import {Link} from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Header1 from './header1';

const RecurringDep=()=>{
return(
  <>
   <Header1/>
   <div className='text-center mt-5 pt-5'>
	<div className='centercontain'>
	<h1 className='fix text-danger' style={{fontFamily: "Raleway", fontWeight: "900", fontStyle: "normal"}}>RECURRING DEPOSIT</h1>
    <img className='' width="100%" height="450px" src="https://www.indusind.com/iblogs/wp-content/uploads/Benefits-of-Recurring-Deposit-IndusInd-Bank.jpg" alt=""/>
	</div>
 </div>
 <div class="container">
	<div class="row mt-5">
			<div class="col-lg-8 inner" style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}}>
			
					<h3><span className='text-primary'>RECURRING</span><span style={{color:"purple"}}> DEPOSIT</span></h3>
					<p >
                    It is a deposit scheme in which members with fixed or regular incomes , deposit a fixed amount every month in their Recurring Deposit Account.
					</p>
				
				
			</div>
			<div class="col-lg-4">
				<img src="https://mukitalk.com/wp-content/uploads/2020/09/Recurring-Deposit-1024x576.jpg" alt="" className='img-responsive img-fluid'/> 
			</div>
			
	</div>
	<div class="row mt-5">
		<div class="container">
			<div class="middle" style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}}>
				<div class="table-responsive">
				  <table class="table table-bordered">
				    <thead className='bg-primary'>
				      <tr>
				        <th>Time Bucket</th>
				        <th>Rate of Interest</th>
				        <th>Time Bucket</th>
				        <th>Rate of Interest</th>
				      
				      </tr>
				    </thead>
				    <tbody>
				      <tr>
				        <td>7 days-14 days</td>
				        <td>4.00%</td>
				        <td>366 days-443 days</td>
				        <td>7.00%</td>
				     
				      </tr>
				      <tr>
				        <td>15 days-30 days</td>
				        <td>4.00%</td>
				        <td>444 days</td>
				        <td>7.50%</td>
				       
				      </tr>
				      <tr>
				        <td>31 days-45 days</td>
				        <td>5.25%</td>
				        <td>445 days-554 days</td>
				        <td>7.00%</td>
				      
				      </tr>
				      <tr>
				        <td>46 days-90 days</td>
				        <td>5.25%</td>
				        <td>555 days</td>
				        <td>7.25%</td>
				      
				      </tr>
				      <tr>
				        <td>91 days-120 days</td>
				        <td>6.00%</td>
				        <td>556 days-2 years</td>
				        <td>7.00%</td>
				       
				      </tr>
				      <tr>
				        <td>121 days-180 days</td>
				        <td>6.00%</td>
				        <td>2 years-3 years</td>
				        <td>7.00%</td>
				     
				      </tr>
				    </tbody>
				  </table>
				</div>
			</div>
		</div>
	</div>
	<div class="row  mb-2 mt-2">
		<div class="container">
			<div class="col-md-12" style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}}>
				<div class="inner mt-2">
					<h3 style={{color:"purple"}}>Features<span> And Benefits</span></h3>
					<p><i class="fa fa-angle-double-right"></i>	Investments starts from Rs.100 and above.</p> 
					<p><i class="fa fa-angle-double-right"></i>	Rate of Interest 4% to 7%.</p> 
					<p><i class="fa fa-angle-double-right"></i> Up to Rs.2 Crores of deposit - Penalty of 1% from applicable ROI.</p>
					<p><i class="fa fa-angle-double-right"></i> Above Rs.2 Crores of deposit - Pre-Closure Penalty norms applicable.</p>
					<p><i class="fa fa-angle-double-right"></i>	No interest is payable for NBE/NRO Deposits, if the deposits is not with the bank for minimum period which is at present is one year. </p>
					<p><i class="fa fa-angle-double-right"></i> For more details, refer "policy on Deposit" from our Corporate Site.</p>
					
				</div>
			</div>
		</div>		
	</div>
</div>
    <Footer/>
    </>
)
};
export default RecurringDep;