import React from "react";
import Header from "./header";
import Header1 from "./header1";
import Footer from "./footer";
import $ from 'jquery';
const Career=()=>{
  $(document).ready(function() {

    $('.applybtn').hover(

       function () {
          $(this).css({"background-color":"red"});
       }, 

       function () {
          $(this).css({"background-color":"blue"});
       }
    );

 });
    return(
        <>
        <Header1/>
        <section id="career" class="career mt-5  PT-3" style={{ backgroundColor: "rgb(30, 39, 53)"}}>
      <div class="container aos-init aos-animate" data-aos="fade-up" style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}} >
        <div class="section-header mt-4">
          <h2><b className="text-danger" style={{fontFamily: "Outfit", fontWeight: "900", fontStyle: "normal"}}>CAREERS </b></h2>
          <p style={{fontFamily:'Franklin Gothic Medium', fontSize: '25px',color: '#ffec78',textTransform: 'uppercase'}}>
            Welcome to COEQUAL NIDHI LIMITED. &amp; Join Our Creative &amp; Innovative Team !!! <br/>
            <span style={{color: 'rgb(149, 0, 255)'}}>LET US WORK TOGETHER AND ACHIEVE GREAT THINGS. <br/>
              </span></p><p style={{fontFamily:'Franklin Gothic Medium', fontSize: '25px', color: '#ffec78', 
              marginTop:'-5px'}}>
                WELCOME ABOARD!!! </p>
            
          <p></p>
          <br/>
          <br/>
          <br/>
          <h5 style={{marginTop:'1px', color: 'whitesmoke', opacity:'0.8'}}> We are delighted to have an enthusiastic professional with a creative mind among us.
          </h5>
        <div class="row">
          <div class="col-lg-6">
            <h6 style={{fontSize:'25px', marginTop: '18px',marginBottom:' 20px',textAlign: 'left', color: "whitesmoke" ,opacity:'0.8'}}>We are Hiring for the
              following positions:-</h6>
            <div class="link-animated d-flex flex-column justify-content-start">
             
               
              <a class="h6 fw-semi-bold bg-light text-decoration-none rounded py-2 px-3 mb-2" href="#">
                <details>
                  <summary className="text-start fs-5"> Senior Legal Advisor 
                  </summary>
                  <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>
                                  <h6 class="fw-bold">Job Overview</h6>
                                  As a Senior Legal Advisor, you will be responsible for providing high
                                  -level legal guidance and support to our organization. You will manage
                                  complex legal issues, ensure compliance with relevant laws and
                                  regulations, and contribute to strategic decision-making processes.  
                                  Your expertise will help mitigate legal risks and drive the company’s
                                  objectives forward.<br/><br/>
                                  
                                  <h6 class="fw-bold">Key Responsibilities: </h6>
                                  •	Legal Guidance • Risk Management • Contract Management • Compliance
                                  • Litigation Management • Policy Development • Strategic Support •
                                  Research • Mentorship<br/><br/>
                                  <h6 class="fw-bold">Qualifications: </h6>
                                  •	Education: Juris Doctor (JD) degree or equivalent from an accredited law school.<br/>
                                  •	Experience: Minimum of 3 years of relevant legal experience, including 2 years in a senior or leadership role.<br/>
                                  •	Expertise: Proven expertise in corporate law, contract law, regulatory compliance, and litigation.<br/>
                                  •	Skills:<br/>
                                   &nbsp;&nbsp;&nbsp;Excellent analytical and problem-solving skills.<br/>
                                   &nbsp;&nbsp;&nbsp;Strong negotiation and drafting abilities.<br/>
                                   &nbsp;&nbsp;&nbsp;Exceptional communication and interpersonal skills.<br/>
                                   &nbsp;&nbsp;&nbsp;Ability to manage multiple priorities and work
                                   under pressure.<br/>
                                   &nbsp;&nbsp;&nbsp;Proficiency in legal research tools and software.
                                  <br/><br/>
                                  <h6 class="fw-bold">Perks and Benefits:</h6>
                                     &nbsp;&nbsp;&nbsp;:: As Per Company Standards. Remuneration is not a constraint for right candidate.<br/>
                                     &nbsp;&nbsp;&nbsp;:: Good Communication Skills (Spoken and Written)<br/>
                                     &nbsp;&nbsp;&nbsp;:: Good Managerial Skill<br/>
                                     &nbsp;&nbsp;&nbsp;:: Experience - 3 +years of experience can apply<br/>
                                     &nbsp;&nbsp;&nbsp;:: Cell phone reimbursement<br/>
                                     &nbsp;&nbsp;&nbsp;:: Leave encashment<br/>
                                     &nbsp;&nbsp;&nbsp;:: Paid sick time<br/><br/>
                                     
                                    <h6 class="fw-bold">Schedule:</h6>
                                    Day shift<br/>
                                    Job Types: Full-time, Permanent<br/><br/>
                                    <spam class="fw-semi-bold">Speak with the employer +91 9606456982 </spam>
                              </p>
                </details>
              </a>
             
              
              <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
              <details>
                                <summary className="text-start fs-5" >Client Relationship Manager</summary>
                                <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>
                                    <h6 class="fw-bold">Job Overview</h6>
                                    COEQUAL Services serves customer segments such as individuals,   business, government, and retail institutions in all financial related products. The relationship teams and the digital channels complement each other to offer a unique value proposition to our customers.
                                    <br/><br/>
                                    <h6 class="fw-bold">Job Summary</h6>
                                    The Client Relationship Manager (CRM) will be responsible for maintaining and growing the relationship between our company and its clients. The CRM will work closely with clients to ensure they are satisfied with the services provided, address any concerns, and work on strategies to enhance the client experience. The role requires excellent communication skills, a deep understanding of our services, and the ability to manage multiple client accounts.<br/><br/>
                                    We are looking for young, dynamic male / female professionals<br/><br/>
                                    In our effort to be the NBFC of Choice, we continuously strive to
                                    understand and serve the financial needs of our customers. <br/><br/>
                                    The process includes:<br/><br/>
                                     
                                    <spam class="fw-semi-bold">COEQUAL SERVICES: (ROI & SCHEMES)</spam><br/>
                                    • Client Acquisition and Relationship Management<br/>
                                    • Conduct client meetings, presentations, and negotiations to close deals and secure new business.<br/>
                                    • Monitor and analyse performance, adjusting strategies as necessary to achieve targets.<br/>
                                    • Contribute to the Company's overall strategic planning by providing input on market opportunities and potential growth areas.<br/>
                                    • Ensure a high level of customer satisfaction by delivering exceptional service and support.<br/>
                                    <br/><br/>
                                    <h6 class="fw-bold">Key Responsibilities: </h6>
                                    • Client Management • Account Growth • Problem Resolution • Reporting and Analysis • Collaboration and Communication
                                    <br/><br/>
                                    <h6 class="fw-bold">Qualifications: </h6>
                                    • Education: Bachelor’s degree in Business, Marketing, or a related field.<br/>
                                    • Experience: <br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Client Relationship Manager - 7+ years of experience.<br/> 
                                        &nbsp;&nbsp;&nbsp;&nbsp;Client Relationship Executive - 3+ years of experience in client
                                        relationship management, account management, or a similar rol
                                        .<br/>
                                    • Skills:<br/>
                                    &nbsp;&nbsp;&nbsp;English, Kannada, Hindi is Mandatory.<br/>
                                    &nbsp;&nbsp;&nbsp;Proven track record of managing and growing client accounts.<br/>
                                    &nbsp;&nbsp;&nbsp;Strong interpersonal and communication skills.<br/>
                                    &nbsp;&nbsp;&nbsp;Ability to work independently and manage multiple client accounts.<br/>
                                    &nbsp;&nbsp;&nbsp;Proficiency in CRM software and Microsoft Office Suite.<br/>
                                    &nbsp;&nbsp;&nbsp;Excellent problem-solving skills and attention to detail.
                                    <br/><br/>
                                    {/* <!--<h6 class="fw-bold">Perks and Benefits:</h6>-->
                                    <!--&nbsp;&nbsp;&nbsp;:: Competitive salary and performance-based bonuses.<br/>-->
                                    <!--&nbsp;&nbsp;&nbsp;:: Health, dental, and vision insurance.<br/>-->
                                    <!--&nbsp;&nbsp;&nbsp;:: Paid time off and flexible working hours.<br/>-->
                                    <!--&nbsp;&nbsp;&nbsp;:: Opportunities for professional development and career advancement.<br/>-->
                                    <!--&nbsp;&nbsp;&nbsp;:: Collaborative and innovative work environment.<br/><br/>--> */}
                        
                                    <h6 class="fw-bold">Schedule:</h6>
                                    Day shift<br/>
                                    Job Types: Full-time, Permanent<br/><br/>
                                    <span class="fw-semi-bold">Speak with the employer +91 9606456982</span>
                                </p>
                            </details>
              </a>
              <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
                            <details>
                                <summary className="text-start fs-5" >Full Stack Web Developer</summary>
                                <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>
                                    <h6 class="fw-bold">Job Overview</h6>
                                    As a Full Stack Web Developer, you will be responsible for developing and maintaining web applications that offer exceptional user experiences. You will work across the full technology stack, from server-side logic to client-side user interfaces, ensuring high performance and responsiveness to requests from the front end.
                                    <br/><br/>
                                    <h6 class="fw-bold">Key Responsibilities: </h6>
                                    • Develop and Maintain Web Applications • Front-End Development • Back-End Development • Full Stack Integration • Testing and Debugging • Version Control and Deployment • Collaboration and Communication • Continuous Improvement
                                    <br/><br/>
                                    <h6 class="fw-bold">Qualifications: </h6>
                                    • Education: Bachelor’s degree in Computer Science, Information Technology, or a related field (or equivalent experience).<br/>
                                    • Experience: 3+ years of experience in full stack development.<br/>
                                    • Technical Skills:<br/>
                                    &nbsp;&nbsp;&nbsp;Proficiency in front-end languages (HTML, CSS, JavaScript).<br/>
                                    &nbsp;&nbsp;&nbsp;Experience with front-end frameworks (React, Angular, Vue.js).<br/>
                                    &nbsp;&nbsp;&nbsp;Strong knowledge of back-end programming languages (Node.js, Python, Ruby, Java, PHP).<br/>
                                    &nbsp;&nbsp;&nbsp;Experience with database management (SQL, NoSQL).<br/>
                                    &nbsp;&nbsp;&nbsp;Familiarity with RESTful and GraphQL APIs.<br/>
                                    &nbsp;&nbsp;&nbsp;Experience with cloud services (AWS, Azure, Google Cloud).<br/>
                                    &nbsp;&nbsp;&nbsp;Proficient in version control using Git.<br/><br/>
                                    <h6 class="fw-bold">Preferred Qualifications:</h6>
                                    &nbsp;&nbsp;&nbsp;:: Experience with CI/CD pipelines.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Knowledge of containerization technologies like Docker and Kubernetes.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Experience with automated testing frameworks.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Understanding of Agile development methodologies.<br/><br/>
                                    <h6 class="fw-bold">Working Conditions:</h6>
                                    • [Details about remote work, on-site requirements, work hours, etc.]
                                    <br/><br/>
                                    <h6 class="fw-bold">Compensation and Benefits:</h6>
                                    • [Details about salary, benefits, bonuses, etc.]
                                </p>
                            </details>
                        </a>
                        <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
                        <details>
                                <summary className="text-start fs-5">Mobile App Developer</summary>
                                <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>
                                    <h6 class="fw-bold">Job Overview</h6>
                                    We are looking for a skilled Mobile App Developer to join our dynamic team. The ideal candidate will be responsible for the development, testing, and maintenance of high-quality mobile applications. You should have experience in mobile app development for both Android and iOS platforms and be passionate about creating intuitive, user-friendly apps.
                                    <br/><br/>
                                    <h6 class="fw-bold">Key Responsibilities: </h6>
                                    • App Development • Code Quality • Collaboration • Testing • Continuous Improvement • Documentation • API Integration • User Experience
                                    <br/><br/>
                                    <h6 class="fw-bold">Qualifications: </h6>
                                    • Education: Bachelor’s degree in Computer Science, Software Engineering, or a related field (or equivalent work experience).<br/>
                                    • Experience: Minimum of [X] years of experience in mobile app development.<br/>
                                    • Technical Skills:<br/>
                                    &nbsp;&nbsp;&nbsp;Proficiency in programming languages such as Swift, Kotlin, Java, Objective-C, or Dart.<br/>
                                    &nbsp;&nbsp;&nbsp;Experience with cross-platform frameworks like Flutter, React Native, or Xamarin is a plus.<br/>
                                    &nbsp;&nbsp;&nbsp;Strong knowledge of mobile UI/UX design principles and patterns.<br/>
                                    &nbsp;&nbsp;&nbsp;Familiarity with RESTful APIs, third-party libraries, and cloud messaging APIs.<br/>
                                    &nbsp;&nbsp;&nbsp;Understanding of mobile app architecture, design patterns (MVC, MVVM, etc.), and version control (e.g., Git).<br/>
                                    &nbsp;&nbsp;&nbsp;Experience with CI/CD tools and processes is a plus.
                                    <br/><br/>
                                    <h6 class="fw-bold">Preferred Qualifications:</h6>
                                    &nbsp;&nbsp;&nbsp;:: Experience with Agile/Scrum methodologies.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Portfolio of published applications on the App Store or Google Play Store.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Knowledge of DevOps practices related to mobile app development.<br/><br/>
                                    <h6 class="fw-bold">Benefits:</h6>
                                    &nbsp;&nbsp;&nbsp;:: Competitive salary and performance-based bonuses.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Health, dental, and vision insurance.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Paid time off and flexible working hours.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Opportunities for professional development and career advancement.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Collaborative and innovative work environment.
                                </p>
                            </details>
                        </a>
                        <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
                        <details>
                                <summary className="text-start fs-5" >HR Manager / HR Executive</summary>
                                <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>                                    <h6 class="fw-bold">Job Overview</h6>
                                    The job of HR Manager is crucial to business success. People are our most important asset, and you’ll be the one to ensure we have a happy and productive workplace where everyone works to realize our established mission and objectives.
                                    <br/><br/>
                                    <h6 class="fw-bold">Roles and Responsibilities: </h6>
                                    • Shortlist the candidates as per recruiting company requirements • Negotiation with the candidate and/or company if required • Recruit and manage potential candidates from various online as well as offline sectors • Work closely with hiring managers to understand their staffing needs and future hiring requirements • Screen, interview, and select qualified candidates for client openings • Ensure smooth onboarding processes for placed candidates
                                    <br/><br/>
                                    <h6 class="fw-bold">Requirements and Skills:</h6>
                                    • Past good work experience as a job consultant or in a similar role.<br/>
                                    • Experience in sourcing & researching techniques.<br/>
                                    • Solid verbal and written communication skills.<br/>
                                    • MBA in Human Resources Management and Marketing.<br/>
                                    • Willingness to travel.<br/>
                                    • Ability to collaborate between MNCs and Corporate Companies.<br/>
                                    • Identify new business opportunities and contribute to the growth of the company.<br/>
                                    • Proven working experience as an HR Manager or other HR Executive (3+ years).
                                </p>
                            </details>
                        </a>
                        
                        <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
                        <details>
                                <summary className="text-start fs-5" >Trainer</summary>
                                <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>                                    <h6 class="fw-bold">Job Overview</h6>
                                    As a Trainer, you will engage directly with trainees to facilitate their skills development. This involves tailoring instruction methods to suit different learning styles and objectives.
                                    <br/><br/>
                                    <h6 class="fw-bold">Roles and Responsibilities: </h6>
                                    • Develop Training Programs • Assess individual/group training needs • Employ interactive learning techniques and clear communication to keep participants engaged • Design, develop, and update training materials, including manuals, presentations, and assessments • Stay up-to-date with industry developments, best practices, and new technologies to enhance training programs
                                    <br/><br/>
                                    <h6 class="fw-bold">Requirements and Skills:</h6>
                                    • Bachelor’s degree in Education, Training, or a related field.<br/>
                                    • 3+ years of experience in training delivery and development.<br/>
                                    • Proven track record of success in training and development.<br/>
                                    • Excellent communication, presentation, and interpersonal skills.<br/>
                                    • Ability to work with diverse learner groups and stakeholders.<br/>
                                    • Strong knowledge of adult learning principles and instructional design.<br/>
                                    • Experience with e-learning platforms and authoring tools.<br/>
                                    • Knowledge of industry-specific training requirements and regulations.
                                </p>
                            </details>
                        </a>
                        <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
                        <details>
                                <summary className="text-start fs-5" >HR Talent Acquisitions</summary>
                                <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>                                    <h6 class="fw-bold">Job Overview</h6>
                                    Talent Acquisition responsibilities include sourcing candidates through various channels, planning interview and selection procedures, and hosting or participating in career events. To be successful in this role, you should be able to develop long-term recruiting strategies and nurture trusting relationships with potential hires.
                                    <br/><br/>
                                    <h6 class="fw-bold">Roles and Responsibilities: </h6>
                                    • Develop and implement recruitment plans to meet business objectives • Create job postings, advertise job openings, and manage job boards • Utilize various sourcing channels, including social media, employee referrals, and job fairs • Schedule and coordinate interviews with hiring managers and candidates • Extend job offers, negotiate salaries, and ensure smooth onboarding • Collaborate with hiring managers and HR teams to ensure recruitment goals are met
                                    <br/><br/>
                                    <h6 class="fw-bold">Requirements and Skills:</h6>
                                    • Proven track record of success in recruitment and talent management.<br/>
                                    • Excellent communication, interpersonal, and problem-solving skills.<br/>
                                    • Strong knowledge of recruitment software and technologies.<br/>
                                    • Bachelor’s degree in HR, Business, or a related field.<br/>
                                    • 3+ years of experience in talent acquisition and recruitment.
                                </p>
                            </details>
                        </a>
                        <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
                        <details>
                                <summary className="text-start fs-5" >Business Development Manager / Business Development Executive</summary>
                                <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>                                    <h6 class="fw-bold">Job Overview</h6>
                                    We are seeking a dynamic and results-driven Business Development Manager (or Executive) to join our team. The ideal candidate will be responsible for identifying and pursuing new business opportunities, developing strategic partnerships, and driving revenue growth. This role requires excellent communication skills, strategic thinking, and a proactive approach to business development.
                                    <br/><br/>
                                    <h6 class="fw-bold">Key Responsibilities: </h6>
                                    • Lead Generation
                                    • Client Acquisition
                                    • Sales Strategy
                                    • Market Analysis
                                    • Proposal Development
                                    • Relationship Management
                                    • Reporting
                                    • Collaboration
                                    • Event Participation
                                    <br/><br/>
                                    <h6 class="fw-bold">Qualifications: </h6>
                                    • Education: Bachelor’s degree in Business Administration, Marketing, or a related field. An MBA or equivalent advanced degree is a plus.<br/>
                                    • Experience: Minimum of 3 years of experience in business development, sales, or a related field.<br/>
                                    • Skills:<br/>
                                    &nbsp;&nbsp;&nbsp;o Proven track record of meeting or exceeding sales targets.<br/>
                                    &nbsp;&nbsp;&nbsp;o Strong networking and relationship-building skills.<br/>
                                    &nbsp;&nbsp;&nbsp;o Excellent communication and presentation abilities.<br/>
                                    &nbsp;&nbsp;&nbsp;o Proficiency in CRM software and Microsoft Office Suite.<br/>
                                    &nbsp;&nbsp;&nbsp;o Strong analytical and problem-solving skills.<br/>
                                    &nbsp;&nbsp;&nbsp;o Ability to work independently and as part of a team.<br/><br/>
                                    <h6 class="fw-bold">Personal Attributes:</h6>
                                    &nbsp;&nbsp;&nbsp;:: Driven: Highly motivated and goal-oriented with a strong desire to succeed.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Strategic Thinker: Ability to develop and execute strategic plans to drive business growth.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Adaptable: Flexible and able to thrive in a fast-paced, dynamic environment.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Customer-Focused: Committed to understanding and meeting the needs of clients and stakeholders.<br/><br/>
                                    <h6 class="fw-bold">Perks and Benefits:</h6>
                                    &nbsp;&nbsp;&nbsp;:: As Per Company Standards. Remuneration is not a constraint for the right candidate.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Good Communication Skills (Spoken and Written)<br/>
                                    &nbsp;&nbsp;&nbsp;:: Good Managerial Skill<br/>
                                    &nbsp;&nbsp;&nbsp;:: Experience - 3+ years of experience can apply<br/><br/>
                                    <h6 class="fw-bold">Benefits:</h6>
                                    &nbsp;&nbsp;&nbsp;:: Cell phone reimbursement<br/>
                                    &nbsp;&nbsp;&nbsp;:: Leave encashment<br/>
                                    &nbsp;&nbsp;&nbsp;:: Paid sick time<br/><br/>
                                    <h6 class="fw-bold">Schedule:</h6>
                                    Day shift<br/>
                                    Job Types: Full-time, Permanent<br/><br/>
                                    <span class="fw-semi-bold">Speak with the employer +91 9606456982</span>
                                </p>
                            </details>
                        </a>
                        <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
                        <details>
                                <summary className="text-start fs-5" >Senior Accountant</summary>
                                <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>                                    <h6 class="fw-bold">Job Overview</h6>
                                    We are seeking a highly skilled and detail-oriented Senior Accountant to join our finance team. The ideal candidate will be responsible for overseeing accounting operations, ensuring accuracy in financial reporting, and providing strategic financial insights. This role requires strong analytical skills, attention to detail, and the ability to manage complex accounting tasks effectively.
                                    <br/><br/>
                                    <h6 class="fw-bold">Key Responsibilities: </h6>
                                    • Financial Reporting: Prepare and review accurate and timely financial statements, reports, and analyses to ensure compliance with accounting principles and standards.<br/>
                                    • Account Reconciliation: Perform complex account reconciliations and resolve discrepancies to maintain the integrity of financial data.<br/>
                                    • General Ledger Management: Oversee and manage the general ledger, ensuring all transactions are recorded accurately and in a timely manner.<br/>
                                    • Month-End and Year-End Closing: Lead month-end and year-end closing processes, including preparing journal entries and ensuring all financial data is accurately reflected.<br/>
                                    • Audit Coordination: Coordinate with internal and external auditors to facilitate audits and ensure compliance with regulations and standards.<br/>
                                    • Budgeting and Forecasting: Assist in the preparation of budgets and financial forecasts, providing insights and recommendations based on financial analysis.<br/>
                                    • Tax Compliance: Ensure compliance with tax regulations and assist in the preparation of tax returns and filings.<br/>
                                    • Process Improvement: Identify opportunities for process improvements and implement best practices to enhance efficiency in accounting operations.<br/>
                                    • Team Leadership: Mentor and supervise junior accountants, providing guidance and support to ensure their professional growth and development.<br/>
                                    • Financial Analysis: Conduct in-depth financial analysis to support decision-making and strategic planning.
                                    <br/><br/>
                                    <h6 class="fw-bold">Qualifications: </h6>
                                    • Education: Bachelor’s degree in Accounting, Finance, or a related field. A Master’s degree or CPA designation is preferred.<br/>
                                    • Experience: Minimum of 3 years of relevant accounting experience, with at least 2 years in a senior or supervisory role.<br/>
                                    • Knowledge: In-depth knowledge of accounting principles, financial reporting, and regulatory requirements.<br/><br/>
                                    <h6 class="fw-bold">Skills:</h6>
                                    &nbsp;&nbsp;&nbsp;o Proficiency in accounting software and Microsoft Excel.<br/>
                                    &nbsp;&nbsp;&nbsp;o Strong analytical and problem-solving abilities.<br/>
                                    &nbsp;&nbsp;&nbsp;o Excellent attention to detail and organizational skills.<br/>
                                    &nbsp;&nbsp;&nbsp;o Ability to manage multiple priorities and meet deadlines.<br/>
                                    &nbsp;&nbsp;&nbsp;o Strong communication and interpersonal skills.<br/><br/>
                                    <h6 class="fw-bold">Personal Attributes:</h6>
                                    &nbsp;&nbsp;&nbsp;:: Integrity: High level of integrity and professionalism in handling confidential information.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Leadership: Proven leadership skills with the ability to manage and motivate a team.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Proactive: Ability to take initiative and drive continuous improvement.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Adaptability: Flexible and adaptable to changing priorities and processes.<br/><br/>
                                    <h6 class="fw-bold">Perks and Benefits:</h6>
                                    &nbsp;&nbsp;&nbsp;:: As Per Company Standards. Remuneration is not a constraint for the right candidate.<br/>
                                    &nbsp;&nbsp;&nbsp;:: Good Communication Skills (Spoken and Written)<br/>
                                    &nbsp;&nbsp;&nbsp;:: Good Managerial Skill<br/>
                                    &nbsp;&nbsp;&nbsp;:: Experience - 3+ years of experience can apply<br/><br/>
                                    <h6 class="fw-bold">Benefits:</h6>
                                    &nbsp;&nbsp;&nbsp;:: Cell phone reimbursement<br/>
                                    &nbsp;&nbsp;&nbsp;:: Leave encashment<br/>
                                    &nbsp;&nbsp;&nbsp;:: Paid sick time<br/><br/>
                                    <h6 class="fw-bold">Schedule:</h6>
                                    Day shift<br/>
                                    Job Types: Full-time, Permanent<br/><br/>
                                    <span class="fw-semi-bold">Speak with the employer +91 9606456982</span>
                                </p>
                            </details>
                        </a>
                        <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
                        <details>
                                <summary className="text-start fs-5" >Sales Engineer</summary>
                                <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>                                    <h6 class="fw-bold">Key Responsibilities</h6>
                                    • Proper planning and meeting customers and generating enquiries.<br/>
                                    • Handling complaints.<br/>
                                    • Sealing products WTP, STP, RO, ETP etc.<br/>
                                    • Arrange business meetings with prospective clients.<br/>
                                    • Promote the company’s products/services addressing or predicting clients’ objectives.<br/>
                                    • Provide trustworthy feedback and after-sales support.<br/>
                                    • Build long-term relationships with new and existing customers.<br/>
                                    • Developing a business development strategy focused on financial gain.<br/>
                                    • Arranging business development meetings with prospective clients.<br/>
                                    • Develop a growth strategy focused both on financial gain and customer satisfaction.<br/>
                                    • Experience in Water industries preferred like STP, WTP, RO, ETP plants.
                                    <br/><br/>
                                    <h6 class="fw-bold">Requirements and Skills</h6>
                                    • Proven sales track record.<br/>
                                    • Experience in customer support is a plus point.<br/>
                                    • Hands-on experience with selling of Sewage treatment, water treatment, and wastewater treatment.<br/>
                                    • Proficiency in MS Office and CRM software (e.g., Salesforce).<br/>
                                    • Worked in an industry relevant to wastewater treatment & Industrial Water treatment.<br/>
                                    • Market knowledge.<br/>
                                    • Communication and negotiation skills.<br/>
                                    • Ability to build rapport.<br/>
                                    • Time management and planning skills.<br/>
                                    • BSc/B.Tech in business administration, sales, or relevant field.<br/><br/>
                                    <h6 class="fw-bold">Education:</h6>
                                    • Bachelor's (Preferred)
                                    <br/><br/>
                                    <h6 class="fw-bold">Experience:</h6>
                                    • 5+ Years (Preferred)<br/>
                                    • Account management: 1 year (Preferred)<br/>
                                    • Total work: 1 year (Preferred)<br/>
                                    • Sales: 1 year (Preferred)<br/>
                                    • Management: 1 year (Preferred)<br/><br/>
                                    <h6 class="fw-bold">Other Requirements:</h6>
                                    • Ability to Commute: Anywhere in India.<br/>
                                    • Ability to Relocate: Bangalore: Relocate before starting work (Required).<br/><br/>
                                    <h6 class="fw-bold">Package:</h6>
                                    • Depends on your interview & last CTC.
                                    <br/><br/>
                                    <h6 class="fw-bold">Speak with the HR Team:</h6>
                                    • +91 9606456982
                                    <br/><br/>
                                    <h6 class="fw-bold">Job Type:</h6>
                                    • Full-time
                                    <br/><br/>
                                    <h6 class="fw-bold">Benefits:</h6>
                                    • Paid sick time
                                    <br/><br/>
                                    <h6 class="fw-bold">Schedule:</h6>
                                    • Day shift
                                    <br/><br/>
                                    <h6 class="fw-bold">Supplemental Pay:</h6>
                                    • Performance bonus
                                    <br/><br/>
                                    <h6 class="fw-bold">Experience:</h6>
                                    • Total work: 5 years (Preferred)<br/>
                                    • Sales: 5 years (Preferred)
                                </p>
                            </details>
                        </a>
                        <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
                        <details>
                                <summary className="text-start fs-5" >Real Estate Sales Consultant</summary>
                                <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>                                    <h6 class="fw-bold">Job Summary</h6>
                                    As a Real Estate Sales Consultant, you will be responsible for guiding clients through the process of buying, selling, and renting properties. You will provide expert advice on the real estate market, assist in property valuations, negotiate deals, and ensure a smooth transaction process. Your goal is to help clients achieve their real estate objectives while delivering exceptional customer service.
                                    <br/><br/>
                                    <h6 class="fw-bold">Roles and Responsibilities</h6>
                                    • Client Consultation: Meet with clients to understand their property needs and preferences.<br/>
                                    • Provide advice on market conditions, prices, mortgages, and legal requirements.<br/>
                                    • Property Listings: Source and list properties for sale or rent on various platforms.<br/>
                                    • Ensure listings are up-to-date with accurate information and attractive descriptions.<br/>
                                    • Property Showings: Arrange and conduct property viewings with potential buyers or renters.<br/>
                                    • Highlight the features and benefits of each property.<br/>
                                    • Negotiation: Negotiate offers between buyers and sellers to achieve a favorable outcome for all parties.<br/>
                                    • Prepare and present offers to clients and provide guidance on counteroffers.<br/>
                                    • Market Analysis: Conduct market research to identify trends and property values in different areas.<br/>
                                    • Provide clients with accurate market insights to inform their decisions.<br/>
                                    • Documentation: Assist in the preparation of contracts, agreements, and other legal documents.<br/>
                                    • Ensure all documentation is completed accurately and in a timely manner.<br/>
                                    • Client Relationship Management: Build and maintain strong relationships with clients, ensuring repeat business and referrals.<br/>
                                    • Provide ongoing support to clients even after the transaction is complete.<br/>
                                    • Networking: Build a network of contacts in the real estate industry, including mortgage brokers, inspectors, and legal professionals.<br/>
                                    • Attend industry events and seminars to stay updated on market trends.
                                    <br/><br/>
                                    <h6 class="fw-bold">Qualifications</h6>
                                    • Proven experience as a Real Estate Sales Consultant or similar role.<br/>
                                    • In-depth knowledge of the local real estate market and property laws.<br/>
                                    • Strong negotiation and communication skills.<br/>
                                    • Ability to build and maintain relationships with clients.<br/>
                                    • Proficiency in real estate management software and MS Office.<br/>
                                    • Valid real estate license in the state of operation.<br/>
                                    • High school diploma or equivalent (Bachelor’s degree in business, marketing, or related field preferred).
                                    <br/><br/>
                                    <h6 class="fw-bold">Skills and Competencies</h6>
                                    &nbsp;&nbsp;&nbsp;o Sales-Oriented: Strong sales skills with a focus on achieving targets and closing deals.<br/>
                                    &nbsp;&nbsp;&nbsp;o Customer Service: Excellent customer service skills with a client-first approach.<br/>
                                    &nbsp;&nbsp;&nbsp;o Detail-Oriented: Attention to detail in all aspects of the sales process.<br/>
                                    &nbsp;&nbsp;&nbsp;o Time Management: Ability to manage multiple clients and properties simultaneously.<br/>
                                    &nbsp;&nbsp;&nbsp;o Problem-Solving: Capable of handling issues that arise during transactions with creativity and professionalism.<br/>
                                    &nbsp;&nbsp;&nbsp;o Self-Motivated: Ability to work independently with minimal supervision.
                                    <br/><br/>
                                    <h6 class="fw-bold">Work Environment</h6>
                                    • This role involves both office work and travel to various properties.<br/>
                                    • Requires flexibility to work evenings and weekends as needed.
                                    <br/><br/>
                                    <h6 class="fw-bold">Compensation</h6>
                                    • Base salary with commission on sales.<br/>
                                    • Performance-based bonuses.<br/><br/>
                                    <h6 class="fw-bold">Job Types:</h6>
                                    Full-time, Permanent
                                    <br/><br/>
                                    <h6 class="fw-bold">Benefits:</h6>
                                    • Cell phone reimbursement<br/>
                                    • Internet reimbursement
                                    <br/><br/>
                                    <h6 class="fw-bold">Schedule:</h6>
                                    • Day shift
                                    <br/><br/>
                                    <h6 class="fw-bold">Supplemental Pay:</h6>
                                    • Commission pay<br/>
                                    • Performance bonus<br/>
                                    • Yearly bonus
                                    <br/><br/>
                                    <h6 class="fw-bold">Experience:</h6>
                                    • Total work: 3 years (Preferred)
                                    <br/><br/>
                                    <h6 class="fw-bold">Work Location:</h6>
                                    In person
                                </p>
                            </details>
                        </a>
                        <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
                        <details>  
                                <summary className="text-start fs-5" >Digital Marketing Specialist</summary>  
                                <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>                                    <h6 class="fw-bold">Job Overview</h6>  
                                    We are looking for an experienced or intern for the Digital Marketing Executive / Digital Marketing Specialist role to assist in the planning, execution, and optimization of our online marketing efforts. The promotion of products and services through digital channels is a complex procedure with great potential which becomes increasingly useful for companies such as ours.  
                        
                                    <br/><br/>  
                                    <h6 class="fw-bold">Key Responsibilities:</h6>  
                                    • Assisting in the formulation of strategies to build a lasting digital connection with consumers <br />  
                                    • Planning and monitoring the ongoing company presence on social media <br />  
                                    • Launching optimized online advertisements to increase company and brand awareness <br />  
                                    • Be actively involved in SEO efforts (keyword, image optimization, etc.) <br />  
                                    • Prepare online newsletters and promotional emails and organize their distribution through various channels <br />  
                                    • Provide creative ideas for content marketing and update the website <br />  
                                    • Collaborate with designers to improve user experience <br />  
                                    • Measure performance of digital marketing efforts using a variety of web analytics tools (Google Analytics, Web Trends, etc.) <br />  
                                    • Acquire insight into online marketing trends and keep strategies up-to-date <br />  
                                    • Maintain partnerships with media agencies and vendors. <br />  
                        
                                    <br/><br/>  
                                    <h6 class="fw-bold">Requirements and Skills:</h6>  
                                    • Proven experience as Digital Marketing Executive or similar role <br />  
                                    • Excellent understanding of digital marketing concepts and best practices <br />  
                                    • Experience with B2C social media, Google AdWords, email campaigns, and SEO/SEM <br />  
                                    • Working knowledge of ad serving tools (e.g., DART, Atlas) <br />  
                                    • Perfect knowledge of web analytics tools (e.g., Google Analytics, Net Insight, and Web Trends, etc.) <br />  
                                    • Skills and experience in creative content writing <br />  
                                    • Analytical mindset and critical thinking <br />  
                                    • Excellent communication and interpersonal skills <br />  
                        
                                    <br/><br/>  
                                    <h6 class="fw-bold">Salary:</h6>  
                                    ₹20,000.00 - ₹40,000.00 per month <br />  
                        
                                    <br/>  
                                    <h6 class="fw-bold">Education</h6>
                                    Bachelor's (Preferred)<br/>
                                    Digital marketing certification.<br/><br/>
                                    
                                    <h6>Experience</h6>
                                    Digital marketing: 1 year (Preferred)<br/>
                                    total work: 1 year (Preferred)<br/>  
                                    Internship experience (Preferred)<br/><br/>
                                    
                                    <h6 class="fw-bold">Schedule</h6> 
                                    
                                    Day shift <br />  
                                    Job Types: Full-time, Permanent <br />  
                                    Work Location: In person <br />  
                                    <span class="fw-semi-bold">Speak with the employer +91 9606456982</span>  
                                </p>  
                            </details>  
                        </a>
          
              {/* <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
                <details>
                  <summary className="text-start fs-5"> Sales Executive 
                  </summary>
  
                  <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>
                    Responsible for B2B &amp; B2C business, by working with Corporate Clients &amp; Customers, to achieve assigned revenue targets.<br/>
                    Closely work with internal teams like the delivery team, to ensure that customer commitments are fully taken care of and
                    best learning experience is provided.<br/>
                    Generate profitable revenue growth through establishing new customers, penetrating existing customer accounts and
                    retaining those customers Effectively assess customer needs, present products and solutions, and close targets.
                    Development of proposals, agreements and pitches for prospective Clients Personal visits to corporates involving
                    presentations of the company and ensuring maximum customer satisfaction.
                    Work with management, to strategize business growth and execute the strategies.
                    Meet weekly, monthly and quarterly sales quotas.
                    Prepare and submit weekly sales reports to management.
                    Learn how products work and how to troubleshoot issues with customers
                    <br/>  
                  </p>
                </details>
              </a>
         
              <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
                <details>
                  <summary className="text-start fs-5"> Legal Advisor Corporate Affairs 
                  </summary>
  
                  <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>
                  Advise and counsel internal clients and stakeholders across the organization on a wide range of legal issues in areas such as: Corporate Law, Competition Law, Compliance, Regulatory, and Corporate Governance matters, Data Privacy, IPR, Labour Employment Law, and Product Liability issues.
                  Draft and negotiate a variety of commercial agreements such as: Employment Agreements, Framework Agreements, Letters of Intent, Master Service Agreements, Memorandum of Understanding, Non-Disclosure Agreements, Statements of Work, and the like;
                  Undertake policy advisory, drafting, review and revisions in line with evolving Laws and changing industry landscape.
                  Advise and counsel on counsel matters arising under: anti-corruption laws, anti-trust laws, relevant industry codes of conduct and the Company s own Code of Conduct and Ethics.
                  <br/>  
                  </p>
                </details>
              </a>
              <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
                <details>
                  <summary className="text-start fs-5">Human Resource Manager  
                  </summary>
  
                  <p style={{textAlign: 'left',margintop: '10px', fontsize:'15px', fontFamily:'Trebuchet MS', color: 'black'}}>
                      Bridge management and employee relations by addressing demands, grievances or other issues <br/>
                      Manage the recruitment and selection process <br/>
                      Support current and future business needs through the development, engagement, motivation and preservation of human capital<br/>
                      Develop and monitor overall HR strategies, systems, tactics and procedures in the organization<br/>
                      Nurture appositive working environment<br/>
                      Over see and manage a performance appraisal system that drives high performance<br/>
                      Maintain pay plan and benefits program<br/>
                      Report to management and provide decision support through HR metrics<br/>
                      Ensure legal compliance throughout human resource management</p><br/>
                    <br/>  
                
                
                </details>
              </a>
           
              <a class="h6 fw-semi-bold bg-light rounded  text-decoration-none py-2 px-3 mb-2" href="#">
                <details>
                  <summary className="text-start fs-5"> Business Development Manager 
                  </summary>
  
                  <p style={{textAlign: 'left',marginTop: '10px',fontSize:'15px',fontFamily:'Trebuchet MS', color: 'black'}}>
                    We are looking for a dynamic both "MALE &amp; FEMALE" Business Development Manager.
                    Candidates should possess both a business development and account management background that enables him or her to drive engagement with respective stakeholders.
                    She should also be a self-starter who is prepared to develop and execute against the market needs / trends.

                    <br/>  
                  </p>
                </details>
              </a>
              <a class="h6 fw-semi-bold bg-light rounded text-decoration-none py-2 px-3 mb-2" href="#">
                <details>
                  <summary className="text-start fs-5">Staff Nurse 
                  </summary>
  
                  <p style={{textAlign: 'left',marginTop: '10px',fontSize:'15px',fontFamily:'Trebuchet MS', color: 'black'}}>
                    0 to 5 yrs experience, looking for opportunity to climb up your career ladder. We have an excellent opportunity for
                    you.
                    We are looking for FEMALE STAFF NURSE for Bangalore location (Banaswadi Branch).
                    Nursing Drive / Assistance for our New Office at Banaswadi, Bangalore - 560 043.
                    Nursing registration is must, flexible to work in rotational shift.
                    Shifts: Should be flexible with all shifts including night shifts
                    Accommodation & Food will be Provided (Terms & Conditions Applies)
                    Only Female candidates can apply.

                    <br />  
                  </p>
                </details>
              </a> */}
            
            </div>
          </div>
          <div class="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
            <h2 class="text-center"  style={{marginTop:'10px', color: 'whitesmoke', opacity:'0.8'}}>Career Growth !!!!</h2>

            <div class="career-form" style={{marginTop: '20px'}}>
            <form id="contactForm" action="https://formsubmit.co/hr@coequalservices.com" method="POST"
             enctype="multipart/form-data" >
                <input type="hidden" name="career_page" value="1"/>
                <div class="row g-3">
                    <div class="col-md-6">
                        <input type="text" id="name" name="name" class="form-control border-0 bg-light px-4" placeholder="Your Name" style={{height: '55px'}} required=""/>
                    </div>
                    <div class="col-md-6">
                        <input type="email" id="email" name="email" class="form-control border-0 bg-light px-4" required="" placeholder="Your Email" style={{height: '55px'}}/>
                    </div>
                    <div class="col-12">
                        <select id="job" name="job" class="form-control border-0 bg-light px-4" placeholder="Select profile" style={{height: '55px'}} required="">
                            <option value="job" selected="">--Select Profile--</option>
                            <option value="Senior Legal Advisor">Senior Legal Advisor</option>
                                    <option value="Client Relationship Manager">Client Relationship Manager</option>
                                    <option value=">Full Stack Web Developer">Full Stack Web Developer</option>
                                    <option value="Mobile App Developer">Mobile App Developer</option>
                                    <option value="HR Manager / HR Executive">HR Manager / HR Executive</option>
                                    <option value="Trainer">Trainer</option>
                                    <option value="HR Talent Acquisitions">HR Talent Acquisitions</option>
                                    <option value="Business Development Manager / Business Development Executive">Business Development Manager / Business Development Executive</option>
                                    <option value="Senior Accountant">Senior Accountant</option>
                                    <option value="Real Estate Sales Consultant">Real Estate Sales Consultant</option>
                                    <option value="Sales Engineer">Sales Engineer</option>
                                    <option value="Digital Marketing Specialist">Digital Marketing Specialist</option>
                            {/* <option value="Sales Executive">Sales Executive</option>
                            <option value="Legal Advisor Corporate Affairs">Legal Advisor Corporate Affairs</option>
                            <option value="Human Resources Manager">Human Resources Manager</option>
                            <option value="Business Development Manager"> Business Development Manager </option>
                           <option value="Staff Nuurse"> Staff Nurse</option> */}
                         
                          </select>
                    </div>
                    <div class="col-12">
                        <input type="text" id="subject" name="subject" class="form-control border-0 bg-light px-4" placeholder="Subject" style={{height: '55px'}}/>
                    </div>
                    <div class="col-12">
                        <textarea id="message" name="message" class="form-control border-0 bg-light px-4 py-3" rows="4" placeholder="Message"></textarea>
                    </div>
                    <div class="col-12">
                        <input type="file" id="file" name="file" accept=".doc,.pdf" class="form-control border-0 bg-light px-4 py-3" rows="4" placeholder="Upload resume!" required=""/>
                    </div>
                    <div class="col-12">
                        <button type="submit" value="Submit" name="submit" id="submit" class="btn btn-primary w-100 py-3">
                         Apply Now </button>
                    </div>
                </div>
            </form>
          </div>
          </div>
        </div></div>
        </div>

        
        
        

        <p style={{fontSize: '30px' ,color: 'brown', textAlign: 'center'}}>
          <marquee width="100%" behavior="alternate" data-aos="flip-up" style={{backgroundColor: 'rgba(128, 128, 128, 0.177)'}} class="aos-init aos-animate"> Kindly send your resumes to hr@coequalinfotech.com
          </marquee>
        </p>
    </section>










       
        <Footer/>
        </>
    )
};
export default Career;