import React from 'react';
import {Link} from 'react-router-dom';
import ReactPlayer from "react-player";
import Header from './header';
import Footer from './footer';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import Header1 from './header1';
import "./index.css";

const Loans=()=>{
return(
  <>
  <Header1/>
	
  <Container fluid>
      {/* Title Row */}
      <Row className="border-bottom mt-5" style={{ backgroundColor: '#f8f9fa' }}>
        <Col className="text-left pt-5">
          <h5 style={{ color: '#004ba0' }}>COEQUAL NIDHI Loans</h5>
        </Col>
      {/* /* Breadcrumbs Row */}
      </Row>
      <Row>
        <Col className='mt-3'>
          <Breadcrumb>
            <Breadcrumb.Item className='text-decoration-none' href="#">Gold Loans</Breadcrumb.Item>
            <Breadcrumb.Item className='text-decoration-none' href="#">Personal Loans</Breadcrumb.Item>
            <Breadcrumb.Item className='text-decoration-none' href="#">Property</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        </Row>
    </Container>

  <div className='text-center '>
	<div className='centercontain'> 
	<h1 className='fix text-danger'>RETURN ON INVESMENT</h1>
     <img className='image-contain'  src="Laonshero.png" alt="loans "/>
	</div>
 </div>
 <div class="container-fluid">
	<div class="row mt-5">
			<div class="col-lg-7 inner text-center" style={{fontFamily: "Raleway"-"sans-serif", fontWeight: "900", fontStyle: "normal"}}>
			
					<h3><span className='text-primary'>KAMADHENU DAILY</span><span style={{color:"purple"}}> DEPOSIT SCHEME</span></h3>
						
	</div>
	</div>
  </div>
  
    <Footer/>
    </>
)
};
export default Loans;


