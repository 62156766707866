// import React from 'react';
// import "./md.css"


// const Swiperimage= () => {
//   return (
//     <>

//          <section className='container' id="swiper1" >
//             <div className='section-title text-center position-relative'>
//          <h2 className='text-center'><span class="text-danger">TESTI</span><span style={{color: 'purple'}}>MONIAL</span></h2>
//           <h4 className='text-center'>“A satisfied customer is the best business strategy of all.”</h4>
//           </div>
//          <div class="owl-carousel owl-theme "  data-aos="fade-up">
    
//     <div class="owl-item1 p-2 mt-2" >
//         <div class="">
//             <div class="offset-3">
//                 <img src="sindhu.png" className='rounded-circle h-50 w-50 '
//                     alt=""/>
                   
//             </div>
//             <div class="testimonial bg-light" style={{marginTop:'-80px', borderRadius: '20px'}}>
//               <p className=' justify-content-between p-1 mt-5 pt-4'>
//               <i class="fa fa-quote-left" aria-hidden="true"></i>
//             Very good customer service at this location every time I have been there, however, expect to spend 15 minutes in line most of the time. It’s a very busy location during most hours of the day.
//             <i class="fa fa-quote-right " aria-hidden="true"></i>
//             <span className='mt-2'><b className='ps-5 ms-4 fs-6 mt-2'>Sindhu</b></span><br/>
//             </p>
//             </div>
           
//         </div>
//     </div>
//     <div class="owl-item1">
//         <div class="">
//             <div class="offset-3">
//                 <img src="sneha1.jpeg" height="50px" width="50px" className='rounded-circle h-50 w-50 '
//                     alt=""/>
                    
//             </div>
//             <div class="testimonial bg-light" style={{marginTop:'-80px', borderRadius: '20px'}}>
//               <p className=' p-1 mt-5 pt-4'>
//               <i class="fa fa-quote-left " aria-hidden="true"></i>
//             This organization will deliver nothing less than the best from expertise and professionalism to top-notch communication. You’re sure to be impressed!
//             <i class="fa fa-quote-right " aria-hidden="true"></i>
//             <span className='mt-2'><b className='ps-5 ms-4 fs-6 mt-2'>Sneha</b></span><br/>
//             </p>
//             </div>
            
//         </div>
//     </div>
//     <div class="owl-item1">
//         <div class="">
//             <div class="offset-3">
//                 <img src="hemanth.jpg"  height="50px" width="50px" className='rounded-circle w-50 h-50 '
//                     alt=""/>
                  
//             </div>
//             <div class="testimonial bg-light" style={{marginTop:'-80px', borderRadius: '20px'}}>
//            <p className='rounded p-1 mt-5 pt-4'> 
//            <i class="fa fa-quote-left " aria-hidden="true"></i>
//             Having been a business customer of the bank I can assure you they do what they say and have incredibly professional and friendly employees in COEQUAL NIDHI LTD.
//             <i class="fa fa-quote-right " aria-hidden="true"></i><br/><br/>
//             <span className='mt-2'><b className='ps-5 ms-4 fs-6 mt-2'>Hemanth</b></span><br/>
//             </p>
//             </div>

//         </div>
//     </div>
//     <div class="owl-item1">
//         <div class="">
//             <div class="offset-3">
//                 <img src="SUMITHRA.jpeg"  height="50px" width="50px" className='rounded-circle w-50 h-50'
//                     alt=""/>
//             </div>
//             <div class="testimonial bg-light" style={{marginTop:'-80px', borderRadius: '20px'}}>
//            <p className=' p-1 mt-5 pt-4' >
          
//            <i class="fa fa-quote-left " aria-hidden="true"></i>
//            Everyone is always friendly and willing to help. Always enjoy going in and taking care of my banking and almost always have a friendly conversation with 'COEQUAL GROUP OF COMPANIES'.
//            <i class="fa fa-quote-right " aria-hidden="true"></i> 
//            <span className='mt-2'><b className='ps-5 ms-4 fs-6 mt-2'>Sumithra</b></span><br/>
//            </p>
//             </div>
            
//         </div>
//     </div>
//     <div class="owl-item1">
//         <div class="">
//             <div class="offset-3">
//                 <img src="sukanya.jpeg"  height="50px" width="50px" className='rounded-circle w-50 h-50 '
//                     alt=""/>
                   
//             </div>
//             <div class="testimonial bg-light" style={{marginTop:'-80px', borderRadius: '20px'}}>
//             <p className=' p-1 mt-5 pt-4'>
//             <i class="fa fa-quote-left" aria-hidden="true"></i>
//             I heighly recommend using 'COEQUAL NIDHI' The customer service here is unbeatable. I was having an issue after moving out of state and they went out of their way to help me solve the problem.
//             <i class="fa fa-quote-right " aria-hidden="true"></i>
//             <span className='mt-2'><b className='ps-5 ms-4 fs-6 mt-2'>Sukanya</b></span><br/>
//             </p>
//             </div>
//         </div>
//     </div>
// </div>
//          </section>
//     </>
//   );
// };
// export default Swiperimage;
import React, { useState } from "react";
import { Card, Carousel, Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import "./swipper.css";

const testimonialList = [
	[
		{
			img: "sindhu.png",
			name: "Sindhu",
			comment:
				"Very good customer service at this location every time I have been there, however, expect to spend 15 minutes in line most of the time. It’s a very busy location during most hours of the day.",
		},
		{
			img: "sneha1.jpeg",
			name: "Sneha",
			comment:
				"This organization will deliver nothing less than the best from expertise and professionalism to top-notch communication. You’re sure to be impressed!",
		},
		{
			img: "hemanth.jpg",
			name: "Hemanth",
			comment:
				"Having been a business customer of the bank I can assure you they do what they say and have incredibly professional and friendly employees in COEQUAL NIDHI LTD.",
		},
	],
	[
		{
			img: "SUMITHRA.jpeg",
			name: "Sumithra",
			comment:
				"Everyone is always friendly and willing to help. Always enjoy going in and taking care of my banking and almost always have a friendly conversation with 'COEQUAL GROUP OF COMPANIES'.",
		},
		{
			img: "sukanya.jpeg",
			name: "Sukanya",
			comment:
				"I highly recommend using 'COEQUAL NIDHI' The customer service here is unbeatable. I was having an issue after moving out of state and they went out of their way to help me solve the problem.",
		},
	],
];

const TestimonialItem = ({ testimonial }) => {
    return (
        <Row>
            {testimonial.map((item, i) => {
                const { img, name, comment } = item;
                return (
                    <Col xs={12} sm={6} md={6} lg={4} key={i}>
                        <Card className="ezy__testimonial24-item border-0 p-4 mt-4">
                            <Card.Body>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="me-3">
                                        <img
                                            src={img}
                                            alt=""
                                            className="img-fluid rounded-circle border"
                                            width="85"
                                        />
                                    </div>
                                    <div>
                                        <h4 className="mb-1 fs-4">{name}</h4>
                                    </div>
                                </div>
                                <p className="opacity-75">{comment}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};


TestimonialItem.propTypes = {
	testimonial: PropTypes.array.isRequired,
};

const Testimonial24 = () => {
	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex) => setIndex(selectedIndex);

	return (
		<section className="ezy__testimonial24 dark-gray" data-aos="zoom-in-down" data-aos-duration="1500">
			<Container fluid className="px-4">
				<Row className="align-items-center justify-content-between mb-4 mb-md-5">
					<Col xs={12}  className="text-center">
						<h2 className="ezy__testimonial24-heading m-4">What They Say</h2>
						<hr className="ezy__testimonial24-divider mb-3" />
					</Col>
				
				<Carousel
					activeIndex={index}
					onSelect={handleSelect}
					controls={false}
					indicators={true}
				>
					{testimonialList.map((testimonial, i) => (
						<Carousel.Item key={i}>
							<TestimonialItem testimonial={testimonial} />
						</Carousel.Item>
					))}
				</Carousel>
				</Row>
			</Container>
		</section>
	);
};

export default Testimonial24;
