import React from 'react';
import { Link } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Header1 from './header1';
const About = () => {
  return (
    <>
      {/*headers */}
      <Header1 />

      <section id="aboutus" className='container  mt-5 pt-5' style={{
  backgroundImage: "url('http://www.coequalservices.com/img/background.jpeg')",
  backgroundPosition: "center bottom",
}}>
<div className='secttion-title'>
<h1 className='text-center mt-5 fw-bold text-primary text-uppercase'>ABOUT US
          </h1>
</div>
       
        <div className='row m-3 '>
          <div className='col-lg-5'>
            <div className=' position-relative pb-3'>
              <p className="m-4 text" id="para">
                <b>COEQUAL SERVICES </b>
               Is Established in the year 2022.<br />
               <br/>
               <b>Our Mission</b>:“Grow through Life”.<br/>
                <br/>
                <b>Our Vision</b>:“Quality and Equality”.<br />
                <br/>
                <b>We Value</b>: Equal Opportunity<br/>
                &emsp;&emsp;&emsp;&emsp;&emsp;Responsibility<br/>
                &emsp;&emsp;&emsp;&emsp;&emsp;Ownership<br/>
                &emsp;&emsp;&emsp;&emsp;&emsp;Quality<br/>
                <br />
                <b>COEQUAL NIDHI LIMITED </b> is one of the most diversified deposit-
                taking financial in India. <br />It lends portfolio across retail, SMEs
                and commercial customers in urban and rural India. It accepts
                public and corporate deposits and offers a variety of financial
                services products to its customers. <br />Consumer Finance, SME
                Finance, Durable, Digital and Lifestyle Finance, Vehicle
                Finance, Personal Loan, Home and Gold Loan, E-Commerce,
                Commercial Lending, Vendor Financing, Investment,
                Partnership and Services, Loan against Shares, FD, Property,
                etc.
              </p>
              <button className='rounded shadow btn btn-secondary m-4'>Learn More</button>
            </div>
          </div>
          <div className='col-lg-7'>
            <img src="coequalgroup.jpg" className='bg-white img-fluid' alt="COEQUAL INFOTECH PVT LTD" style={{ width: '650px' }} />
          </div>
        </div>
        <div className='row mt-4 text-center'>
          <h1 className='text-center text-primary'>OUR GROUP OF COMPANIES<hr /></h1>
          <div className='col-lg-12 text-center'>
            <img src="1.jpeg" className='img-fluid' alt="" />
          </div>
        </div>
      </section>
      <Footer/>
    </>
  )
};
export default About;
