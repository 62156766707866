import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom'; // Import Link component
import "./service.css"

const serviceList = [
    {
        image: "recurrringdeposits.jpg",
        title: "RECURRING DEPOSIT",
        // description: "It is a deposit scheme in which members with fixed or regular incomes , deposit a fixed amount every month in their Recurring Deposit Account.",
        link: "/recurringdp" // Add link to each service item
    },
    {
        image: "KDDailydepositescheme.jpeg",
        title: "Kamadhenu Daily Deposit Scheme",
        // description: "It is a monetory deposit scheme introduced to help dailywage earners, small traders,to inculcate saving habits and also as a way of funding their bigger capital requirements.",
        link: "/kamadenu"
    },
    {
        image: "fixeddeposit.jpg",
        title: "Fixed Deposit",
        // description: "It is a scheme where a member makes a onetime investment for a fixed tenure and gets a higher rate of interest.Investments Starts From ₹ 5000 & Above. Rate of Interest 8.50% To 12%.",
        link: "/fixeddeposite"
    },
    {
        image: "returnoninvestment.jpg",
        title: "Return On Investment",
        // description: "It is a deposit scheme in which members with fixed or regular incomes , deposit a fixed amount every month in their Recurring Deposit Account.",
        link: "/roi"
    },
    {
        image: "kalpavruskhaschemes.jpeg",
        title: "Kalpavruksha Scheme",
        // description: "Assumenda non repellendus distinctio nihil dicta sapiente, quibusdam maiores, illum at, aliquid blanditiis eligendi qui.Assumenda non repellendus distinctio nihil dicta sapiente, quibusdam maiores, illum at, aliquid blanditiis eligendi qui.",
        link: "/kalpa"
    },
];


const Service14 = () => {
    return (
        <section className="ezy__service14" style={{
            backgroundImage: "url('http://www.coequalservices.com/img/background.jpeg')",
            backgroundPosition: "center bottom",
            backgroundSize: "cover",
            marginBottom: "30px"
          }}>
            <div
            className="section-title text-center position-relative  mx-auto"
            style={{ maxwidth: 600 }}
          >
            <h2 classNameName="text-center">
              <span style={{ color: "purple" }}>DEPOSIT</span>
              <span style={{ color: "red" }}> SCHEMES</span>
            </h2>
          </div>
          <Container fluid>
            <Row className="justify-content-center" data-aos="zoom-in-up" data-aos-duration="1500">
                {serviceList.map((service, i) => (
                    <Col xs={12} sm={6} md={4} lg={4} className="my-3" key={i}>
                        <Link to={service.link} className="service-link">
                            <ServiceItem service={service} />
                        </Link>
                    </Col>
                ))}
            </Row>
        </Container>
        </section>
    );
};

const ServiceItem = ({ service }) => (
    <div
        className="ezy__service14-card"
        style={{ backgroundImage: `url(${service.image})` }}
    >
        <div className="ezy__service14-popup-card p-3 p-lg-5">
            <h4 className="ezy__service14-title fs-4 mb-3">{service.title}</h4>
            <p className="ezy__service14-content mb-0">{service.description}</p>
        </div>
    </div>
);

ServiceItem.propTypes = {
    service: PropTypes.object.isRequired,
};

export default Service14;