import React from 'react';
import { Link } from 'react-router-dom';

const Header1 = () => {
  return (
    <>
    <header>
  <nav className="navbar navbar-expand-lg fixed-top m-0 pb-0 navbar-scroll">
    <div className="container-fluid d-flex justify-content-between align-items-center">
      {/* <!-- Logo --> */}
       <div>
         <img  src="h1.jpeg" height="80" width="200" alt="h1" />
     </div>

      {/* <!-- Navbar Toggler for mobile view --> */}
     <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      {/* <!-- Navigation Links --> */}
       <div className="collapse navbar-collapse" id="navbarSupportedContent">
         <ul className="navbar-nav ms-auto">
           <li className="nav-item hover">
             <Link className="nav-link active text-dark ms-2 ps-4" aria-current="page" to="/"><strong className="navlink">HOME</strong></Link>
          </li>
           <li className="nav-item hover">
             <Link to="/aboutus" className="nav-link ms-3 text-dark"><b>ABOUT&nbsp;US</b></Link>
           </li>
           {/* Loans */}
         {/* <li className="nav-item hover">
             <Link to="/loans" className="nav-link ms-3 text-dark"><b>LOANS</b></Link>
           </li>  */}
           <li className="nav-item dropdown ms-3 hover">
           <a className="nav-link dropdown-toggle text-dark" href="#" id="navbarDropdown" role="button" aria-expanded="false">
               <b>OUR&nbsp;SERVICES</b>
              {/* <!-- Icon for animation --> */}
               <i class="dropdown-icon"></i> 
             </a>
             <ul className="dropdown-menu p-4" aria-labelledby="navbarDropdown">
               <li><Link className="dropdown-item" to="/fixeddeposite">Fixed Deposit (FD)</Link></li>
               <li><Link className="dropdown-item" to="/recurringdp">Recurring Deposit (RD)</Link></li>
               <li><Link className="dropdown-item" to="/roi">Return On Investment (ROI)</Link></li>
               <li><Link className="dropdown-item" to="/kamadenu">Kamadhenu Daily Deposit Scheme</Link></li>
               <li><Link className="dropdown-item" to="/kalpa">Kalpavruksha Scheme</Link></li>
               <li><Link className="dropdown-item" to="/saving">Saving Account</Link></li>
               <li><Link className="dropdown-item" to="/brouchers">Brochures</Link></li>
              
             </ul>
           </li>
           <li className="nav-item ms-3 hover">
             <Link className="nav-link text-dark" to="/member"><b>MEMBERSHIP</b></Link>
           </li>
           <li className="nav-item ms-3 hover">             <Link className="nav-link text-dark" to="/carer"><b>CAREERS</b></Link>
           </li>
           <li className="nav-item ms-3 hover">
             <Link className="nav-link text-dark" to="/contactone"><b>CONTACT&nbsp;US</b></Link>
           </li>
         </ul>
       </div>     </div>
   </nav>
 </header>

     </>
  );
};

export default Header1;


// import React, { useState } from "react";
// import "./header1.css";

// const Header1 = () => {
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   return (
//     <header className="header navbar-expand-lg fixed-top mt-0 pt-0 navbar-scroll">
      /* <div class="header_midil">
        <div class="container">
          <div class="row d_flex">
            <div class="col-md-4 col-sm-4 d_none">
              <ul class="conta_icon">
                <li>
                  <a href="/">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                   Call Us: +91 8029909931/32
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-4 col-sm-4">
                  <a class="logo" href="/">
                  <img src="h1.jpeg" alt="coequalnidhilimited"/>
                  </a>
            </div>
            <div class="col-md-4 col-sm-4 d_none">
              <ul class="conta_icon" style={{float: 'right'}}>
                <li>
                  <a href="/">
                  <i class='fa fa-envelope' aria-hidden="true"></i>
                  Email US: support@coequalanidhi.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */
      /* <div class="header_bo">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-7">
              <nav class="navigation navbar navbar-expand-md navbar-dark">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarsExample04">
                  <ul class="navbar-nav mr-auto" id="nav-list">
                    <li class="nav-item">
                      <a class="nav-link active" href="/" id="homelink"> Home </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link active" href="/" id="homelink"> Home </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link active" href="/" id="homelink"> Home </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link active" href="/" id="homelink"> Home </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link active" href="/" id="homelink"> Home </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="top-bar">
        <div className="contact-info">
          <span className="contact-number">Call Us: +91 8029909931/32</span>
        </div>
        <div className="logo">
          <img src="h1.jpeg" alt="TPFNL Logo" />
        </div>
        <div className="contact-info">
          <span className="contact-email">Email: support@coequlanidhi.com</span>
        </div>
      </div> */
      /* <nav className="navbar ">
        
        <div className="menu-icon" onClick={toggleMobileMenu}>
          <i className="fas fa-bars"></i>
        </div>
        <ul className={`nav-links ${isMobileMenuOpen ? "open" : ""}`}>
          <li><a href="#home">HOME</a></li>
          <li><a href="#deposits">DEPOSITS</a></li>
          <li><a href="#locker">LOCKER</a></li>
          <li><a href="#loan">LOAN</a></li>
          <li><a href="#tpfnl">TPFNL</a></li>
          <li><a href="#useful-links">USEFUL LINKS</a></li>
          <li><a href="#contact">CONTACT</a></li>
        </ul>
      </nav>
//       </header>
//   );
// };

// export default Header1; */

