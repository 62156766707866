import React from 'react';
import Header1 from './header1';
import Footer from './footer';
const Brochor=()=>{
    return(
        <>
        <Header1/>
        <section id="aboutus" className='container offset-1 mb-2 border rounded shadow'>
    <div className='row mt-5 pt-5'>
        <div className='col-md-4 mb-4'>
            <div className='card'>
                <img src="coequlanidhi.jpeg" className='card-img-top' alt="coequalnidhi 1"/>
            </div>
        </div>
        <div className='col-md-4 mb-4'>
            <div className='card'>
                <img src="FIXED DEPOSIT.png" className='card-img-top' alt="Fixed deposit 2"/>
            </div>
        </div>
        <div className='col-md-4 mb-4'>
            <div className='card'>
                <img src="KALPAVRUKSHA SCHEMES.png" className='card-img-top' alt="Kalpavruksha 3"/>
            </div>
        </div>
    </div>
    <div className='row'>
        <div className='col-md-4 mb-4'>
            <div className='card'>
                <img src="KAMADHENU.png" className='card-img-top' alt="Kamadhenu 4"/>
            </div>
        </div>
        <div className='col-md-4 mb-4'>
            <div className='card'>
                <img src="RECURRING DEPOSIT.png" className='card-img-top' alt="Recurring Deposit 5"/>
            </div>
        </div>
        <div className='col-md-4 mb-4'>
            <div className='card'>
                <img src="7.png" className='card-img-top' alt="Kalpavruksha 6"/>
            </div>
        </div>
    </div>
</section>


        <Footer/>
        </>
    )
};
export default Brochor;