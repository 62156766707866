import React from "react";
import { HashRouter,Route, Routes } from "react-router-dom";
import Header from "./header";
import Body from "./body";
import Footer from "./footer";
import Login from "./login";
import Register from "./register";
import About from "./aboutus";
import Contact from "./contactus";
import Gallery from "./gallery";
import Project from "./project";
import Loans from "./loans";
import FixedDeposit from "./fixeddeposite";
import RecurringDep from "./recurringdp";
import Roi from "./roi";
import Roiprivacy from "./roiprivacy";
import Kamadenu from "./kamadenu";
import Kalpa from "./kalpa";
import ContactOne from "./contactone";
import Career from "./carer";
import Memeber from "./member";
import Testimonial24 from "swiper";
import Privacy from "./privacy";
import Md from "./md";
import Flipper from "./flipper";
import Brochor from "./brouchers";
import Review from "./Review";
// import SideIconBar from "./sideiconbar";
import SimpleForm from "./SimpleForm";
import Saving from "./saving";
import Md1 from "./md1";
import Counter from "./counter";
import Chat from "./chatbox";
// import Legal from "./legal";

function App() {
  return (
    <>
 <HashRouter>
  <Routes>
  <Route exact path="/" element={<Body/>} />
  <Route exact path="./header" element={<Header/>} />
  <Route exact path="/body" element={<Body/>} />
  <Route exact path="/footer" element={<Footer/>} />
  <Route exact path="/login" element={<Login/>} />
  <Route exact path="/login/register" element={<Register/>} />
  <Route exact path="/aboutus" element={<About/>} />
  <Route exact path="/contactus" element={<Contact/>} />
<Route exact path="/gallery" element={<Gallery/>} />
<Route exact path="/project" element={<Project/>} />
<Route exact path="/loans" element={<Loans/>} />
<Route exact path="/fixeddeposite" element={<FixedDeposit/>} />
<Route exact path="/recurringdp" element={<RecurringDep/>} />
<Route exact path="/roi" element={<Roi/>} />
<Route exact path="/kamadenu" element={<Kamadenu/>} />
<Route exact path="/kalpa" element={<Kalpa/>} />
<Route exact path="/contactone" element={<ContactOne/>} />
<Route exact path="/carer" element={<Career/>} />
<Route exact path="/member" element={<Memeber/>} />
<Route exact path="/swiper" element={<Testimonial24/>} />
{/* <Route exact path="/legal" element={<Legal/>} /> */}
<Route exact path="/Roiprivacy" element={<Roiprivacy/>} />
<Route exact path="/privacy" element={<Privacy/>} />
<Route exact path="/md" element={<Md/>} />
<Route exact path="/flipper" element={<Flipper/>} />
<Route exact path="/brouchers" element={<Brochor/>} />
<Route eaxct path="/Review" element={<Review/>} />
<Route exact path="/SimpleForm" element={<SimpleForm/>}/>
<Route exact path="/saving" element={<Saving/>} />
<Route exact path="/md1" element={<Md1/>} />
<Route exact path="/counter" element={<Counter/>} />
<Route exact path="/chatbox" element={<Chat/>} />
  </Routes>
 </HashRouter>
 
    </>
  );
}

export default App;
