import React from 'react';
import Header1 from './header1';
const Privacy=()=>{
    return(
    <>
    <Header1/>
       <div class="container-fluid py-5 mt-3 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="container py-5">
                                <div class="row g-5">
                                    <div class="col-lg-20 col-md-20">
                                       
                                        <div class="mb-5">
                                          <h2 class="mb-4 text-center text-danger" style={{margintop: -40}}><b>COEQUAL</b> INTERNET PRIVACY POLICY</h2>
                                            <h4 class="mb-4">Preamble</h4>
                                            <p class="text">This web site is owned and operated by or on behalf of <b>COEQUAL</b>. We recognize that visitors to our site may be concerned about the information they provide to us, and how we treat that information. This Privacy Policy addresses those concerns. This policy may be changed or updated from time to time by the Board of Directors. If you have any questions or concerns about our Privacy Policy, you can contact us
                                                 by e-mail <a href="mailto:hr@coequalinfotect.com"> hr@coequalinfotect.com</a> or by regular mail sent to the following address:<br/><b><br/><b>COEQUAL INFOTECH</b> <br/>
                                                 #101, Sarovar Mansion, A Block, First Floor,<br/> Dodda Banaswadi Main Rd, <br/> Annaiah Reddy Layout, Banaswadi, Bengaluru, Karnataka 560 043.</b>
                                                  </p>
                                                  <h4 class="mb-4">Our Policy</h4>
                                            <p class="text"><b>COEQUAL</b> uses its best efforts to respect and protect the privacy of our online visitors and donors. Consistent with this objective, <b>COEQUAL</b> does not presently 
                                                and has no current plans to sell any personal information you provide us to unrelated third parties. </p>
                                                <h4 class="mb-4">Collection of Personal Information</h4>
                                            <p class="text">In your efforts to assist <b>COEQUAL</b> in our work, you entrust us to properly protect, manage and keep secure assorted aspects of your personal information. This may include but is not limited to items such as your home address, telephone number, e-mail address, driver's license number, credit card data, and bank account data. <b>COEQUAL</b> appreciates and takes 
                                                very seriously the trust that you place in us by providing this most personal information to us. </p>
                                                <p class="text"><b>COEQUAL</b> does not collect personal information about you unless you voluntarily provide personal information to <b>COEQUAL</b> (e.g., by making a donation or signing up to receive e-mail from <b>COEQUAL</b>). <b>COEQUAL</b> does,
                                                     however, monitor and collect certain navigational information from visitors to our web site including:</p>
                                                     <li>The IP address and domain name from which this web site was accessed.</li>
                                                     <li> The date and time this web site was visited.</li>
                                                     <li>The amount of time spent viewing this site.</li> 
                                                    <li>The web pages or services accessed.</li>
                                                    <li>The web site visited prior to accessing this web site.</li>
                                                     <li>The web site visited after this web site, if applicable.</li>
                                                     <li>Any form that was downloaded, if applicable.</li>
                                                     <br/>
                                                     <h4 class="mb-4"><b>COEQUAL</b>'s Use of Your Personal Information</h4>
                                                     <p class="text">By voluntarily submitting personal information to <b>COEQUAL</b>, you agree that <b>COEQUAL</b> may use that information to maintain contact with you, solicit contributions from you and provide you with information about <b>COEQUAL</b> and its programs and initiatives.
                                                    </p>
                                                    <br/>
                                                    <h4 class="mb-4">Sharing of Data</h4>
                                                    <p class="text"><b>COEQUAL</b> does, from time to time, hire third party vendors to assist it in sending correspondence to those on our mailing lists (regular mail and e-mail) and processing credit card transactions. Every vendor engaged by <b>COEQUAL</b> for this purpose is required to keep confidential and protect your personal information.</p>
                                                    <br/>
                                                    <h4 class="mb-4">"Opt Out"</h4>
                                                    <p class="text"><b>COEQUAL</b> respects your privacy and does not want to contact you unless you indicate to us that you want to be contacted by <b>COEQUAL</b>. When you provide personal information to <b>COEQUAL</b> online, you are given the option of choosing whether or not you would like to be contacted by <b>COEQUAL</b> in the future and the manner in which you would like to be contacted. If you would like to be contacted by <b>COEQUAL</b>, you may choose to be contacted by e-mail, by regular mail or by phone. If you do not want to be contacted by <b>COEQUAL</b> you may "opt out" of receiving future contact from us by selecting "not at all."</p>
                                                     <p class="text">If you initially choose to be contacted by <b>COEQUAL</b>, you may, at any time, change your mind and "opt out" of receiving future contact by notifying us via regular mail or e-mail. Simply inform us that you no longer wish to be contacted by stating: "Do Not Mail," "Do Not E-mail," "Do Not Call," and/or "Do Not Contact" in an e-mail <a href="hr@coequalinfotect.com">hr@coequalinfotect.com</a> or in a letter sent to the following address:</p>
                                                     <b><b>COEQUAL</b> INFOTECH <br/>
                                                        #101, Sarovar Mansion, A Block, First Floor, <br/>
                                                        Dodda Banaswadi Main Rd, <br/>
                                                        Annaiah Reddy Layout, Banaswadi, <br/>
                                                         Bengaluru, Karnataka 560 043. </b><br/> 
                                                        <b><a href="tel:+918029909931">+91-80-29909931</a></b><br/>
                                          <b><a href="tel:+918029909932">+91-80-29909932</a></b>
                                                        <br/><br/>
                                                        <h4 class="mb-4">Data Security</h4>
                                                        <p class="text">We care about the safety and security of your personal information. We have thus established an assortment of safeguards which restrict access to this information to only a limited number of staff. We have also instituted various redundancy systems which enable us to safely maintain the personal information in our possession. The <b>COEQUAL</b> web site is digitally protected as reflected by our participation in and displaying of a valid certification. <b>COEQUAL</b> also archives all data through secure backups that reside behind a firewall and are access-protected. In addition, the company we work with to process credit card transactions use high grade encryption and security protocols.</p>
                                                        <h4 class="mb-4">Data Accuracy</h4>
                                                        <p class="text">In addition to maintaining privacy and security controls over the personal information you share with us, <b>COEQUAL</b> strives to ensure that the personal information we have collected is current and accurate. If you wish to verify, correct or amend the personal information that <b>COEQUAL</b> has collected from you, you may do so by sending an e-mail to <a href="hr@coequalinfotect.com">hr@coequalinfotect.com</a>
                                                        </p>
                                                        <h4 class="mb-4">Children</h4>
                                                        <p class="text"><b>COEQUAL</b>'s web site is not intended for use by children under the age of 13. <b>COEQUAL</b> does not knowingly solicit data online from, or market online to, children under the age of 13. If we learn that we have obtained personal information online in error about a child under the age of 13, we will delete that information as soon as we can.</p>
                                                        <p class="text"><b>COEQUAL</b> appreciates the enthusiasm and support young people show for improving the quality of life of Indians. However, <b>COEQUAL</b> does not accept financial contributions from anyone under 18 years of age. Any young people under the age of 18 who raise money for <b>COEQUAL</b> must have the money transferred by an adult through a check or credit card. <b>COEQUAL</b> will thank both the young people and the adult, but only the adult who makes the contribution will be listed in our database.</p>
                                                        <br/>
                                                        <h4 class="mb-4">Changes to Policy </h4>
                                                        <p class="text">Please contact us should you have any questions about the operation of the site. Any changes to our privacy policy will be noted on the web site. This Privacy Policy is effective as of August 24th, 2022. </p>
                                                        <p class="text"><b>COEQUAL</b> uses cookies to ensure you get the best experience on our website. By continuing to use the site, you agree to our updated cookie policy.</p>
                                                        
                                                        
                                        </div>
                                     
                                    </div>
                                  
                            </div>
                        </div>
                        </div>
                        </>
    
    )
};
export default Privacy;