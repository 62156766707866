import React from 'react';
import Header from './header';
import Footer from './footer';
import Header1 from './header1';


const appdf="Application Form.pdf";
 function openpdf(){
    window.open(appdf,'_blank');
 }
const Memeber=()=>{
    function load(){
        fetch('Application Form.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'Application Form.pdf';
                alink.click();
            })
        })
    }
   

    return(
        <>

<Header1/>
     <div className='container-fluid centerword-container w-100 response'>
     <img src="member1.png" alt="member"  className='img-fluid w-100 h-50' />
     <div className='centerword'>
        <div className='row'>
            <div className='col-lg-12 col-md-6 mt-5'>
                < button onClick={load} className='btn text-decoration-none'>
                    <h5 className='text-primary '>Download&nbsp;Application Form</h5>

                </button>
                <button onClick={openpdf} className='btn text-decoration-none'>
                <h5 className='text-primary '>Open Application Form</h5>
            </button>
            </div>
            </div>
           <div className=''>
           
        <a href='https://form.jotform.com/coequalnidhi/coequal-nidhi-limited' className='btn text-decoration-none'>
        <h5 className='text-primary p-2' style={{ borderStyle: "solid", borderRadius: "10px"}}>FILL&nbsp;FORM</h5></a>

    </div> 
     </div>
    
     </div>
     <img src="qrservice.png" alt="" className='img-fluid'/>
     <Footer/>
      
        </>
    )
};
export default Memeber;