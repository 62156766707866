import React from 'react';
import {Link} from 'react-router-dom';
import { MDBRow, MDBCol, MDBInput, MDBTextArea, MDBBtn } from 'mdb-react-ui-kit';
import Header1 from './header1';
import Footer from './footer';

const ContactOne=()=> {
  return (
    <>
    <Header1/>
    <section className='text-center  w-100 container-fluid ' style={{
  backgroundImage: "url('http://www.coequalservices.com/img/background.jpeg')",
  backgroundPosition: "center bottom",
  backgroundSize: "cover",
  marginBottom: "30px"
}}>
      <div className='section-title' >
      <h2 className='text-primary fw-bold' > 
      <p className='text-danger pt-5 mt-4'>CONTACT<span style={{color:"purple"}}> US</span></p>
    </h2>
    </div>
    
      <div className='row'>
        <div className='col-lg-5 text-center'>
          <iframe
            title="This is a unique title"
            src='https://maps.google.com/maps?width=600&height=400&hl=en&q=Sarovar Mansion, 850, Dodda Banaswadi Main Rd, Annaiah Reddy Layout, Banaswadi, Bengaluru, Karnataka 560043&t=&z=14&ie=UTF8&iwloc=B&output=embed'
            className='h-100 w-100'
            style={{ border: '0' }}
            loading='lazy'
          ></iframe>
        </div>
        <div className='col-lg-7' style={{fontFamily: "Merriweather", fontWeight: "400", fontStyle: "normal"}}>
       
            <div className='row'>
              <div className='col-md-9 ps-3 ms-2'>
              <form action="https://formsubmit.co/support@coequalnidhi.com" method="POST">

    
    <input type="text" name="name" id="" placeholder='Enter Your Name' class="form-control"/>
  

    <input type="email" name="email" id=""  placeholder='Enter Your Email' class="form-control mt-3"/>


    <input type="text" name="number" id="" placeholder='Enter Your number' class="form-control mt-3"/>
  
  
   
    <input type="text" name="plan" id="" placeholder='Enter your plan' class="form-control mt-3"/>
 
     <button className='btn btn-primary mt-3' type="submit">Send</button>
</form>
              </div>
             
            </div>
      <div className='row shadow border m-1 p-2' style={{ borderRadius: '15px' }}>
        <div className='col-lg-3'>
        <i className='fas fa-map-marker-alt fa-2x text-primary'></i>
                    <p>
                    <Link className='text-decoration-none' to="https://coequalnidhi.com/">  <small>COEQUAL NIDHI</small></Link>
                    </p>
        </div>
        <div className='col-lg-3'>
        <i className='fas fa-phone fa-2x text-primary'></i>
                    <p>
                    <a className="text-decoration-none text-dark" href="tel:+918029909931"><smal> +91-80-29909931</smal></a>
                     
                    </p>
        </div>
        <div className='col-lg-3'>
        <i className='fas fa-phone fa-2x text-primary'></i>
                    <p>
                    <a className="text-decoration-none text-dark" href="tel:+918029909931"><small> +91-80-29909932</small></a>
                     
                    </p>
        </div>
        <div className='col-lg-3'>
        <i className='fas fa-envelope fa-2x text-primary'></i>
                    <p>
                     <a className="text-decoration-none text-dark" href="mailto:support@coequalnidhi.com"> <small>support@coequalnidhi.com</small></a>
                    </p>
        </div>
        
        
      </div>
        </div>
      </div>
    </section>
  
    </>
  );
}
export default ContactOne;