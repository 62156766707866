import React,{useState} from 'react';
import {Link,useNavigate} from 'react-router-dom';

const Register=()=>{
    const[fname,pickFName]=useState("");
    const[lname,pickLname]=useState("");
    const[mobile,pickMobile]=useState("");
    const[Email,pickEmail]=useState("");
    const[password,pickPassword]=useState("");
    const[confirmpass,pickConpass]=useState("");
    //const[dob,pickDob]=useState("");
    const[day,pickday]=useState("");
    const[month,pickmonth]=useState("");
    const[year,pickyear]=useState("");
    const[address,pickAddress]=useState("");
    const[pincode,pickPincode]=useState("");
    const[state,pickState]=useState("");
    const[district,pickDistrict]=useState("");
    const[tc,picktc]=useState("");
    const[msg,updateMsg]=useState("");
    
    var formstatus = true;
    const[details,updateDetails]=useState([]);
     const[fnameError, updateNameError] = useState('');
     const[lnameError,updateLnameError]=useState('');
     const[mobileError,updateMobileError]=useState('');
     const[emailError,updateEmail]=useState("");


    const navigate=useNavigate();
    const clear=()=>{
        pickFName("");
    pickLname("");
    pickEmail("");
    pickAddress("");
    pickConpass("");
    pickDistrict("");
    pickday("");
    pickmonth("");
    pickyear("");
    pickPassword("");
    pickMobile("");
    pickPincode("");
    pickState("");
    }

    return(
<>

<div className='container-fluid backreg '>
 <div className='row'>
 
    <div className='col-lg-5 bg-light mt-2 mb-4 border shadow rounded offset-4 text-center '>
   
    <hr/>
    <form >
    <h3 className='text-primary'>Register As Member</h3>
    <div className='row m-1 p-1 '>
        <div className='col-lg-3'>
            <label>Full Name</label>
            </div>
            <div className='col-lg-9'>
                <input type="text" id="fname" className='form-control sm' onChange={obj=>pickFName(obj.target.value)} value={fname}/>
                <i className='text-danger'> {fnameError} </i>
            </div>
    </div>
   
    <div className='row m-1'>
        <div className='col-lg-3'>
            <label>Mobile</label>
            </div>
            <div className='col-lg-9'>
                <input type="number" className='form-control sm' onChange={obj=>pickMobile(obj.target.value)} value={mobile}/>
           <i className='text-danger'>{mobileError}</i>
            </div>
    </div>
    <div className='row m-1'>
        <div className='col-lg-3'>
            <label>Email id</label>
            </div>
            <div className='col-lg-9'>
                <input type="email" className='form-control sm' onChange={obj=>pickEmail(obj.target.value)} value={Email}/>
            </div>
    </div>
    <div className='row m-1'>
        <div className='col-lg-3'>
            <label>Password</label>
            </div>
            <div className='col-lg-9'>
                <input type="password" className='form-control sm' onChange={obj=>pickPassword(obj.target.value)} value={password}/>
            </div>
    </div>
    <div className='row m-1'>
        <div className='col-lg-3'>
            <label>Confirm password</label>
            </div>
            <div className='col-lg-9'>
                <input type="password" className='form-control sm' onChange={obj=>pickConpass(obj.target.value)} value={confirmpass}/>
            </div>
    </div>
    <div className='row m-1'>
        <div className='col-lg-3'>
            <label>Date Of Birth</label>
            </div>
                <div className='col-lg-9'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <select className='form-select sm' onChange={obj=>pickday(obj.target.value)} value={day}>
                                <option>Day</option>
                                <option>01</option>
                                <option>02</option>
                                <option>03</option>
                                <option>04</option>
                            </select>
                        </div>
                        <div className='col-lg-4'> <select className='form-select sm' onChange={obj=>pickmonth(obj.target.value)} value={month}>
                                <option>Month</option>
                                <option>01</option>
                                <option>02</option>
                                <option>03</option>
                                <option>04</option>
                            </select></div>
                        <div className='col-lg-4'>
                        <select className='form-select sm' onChange={obj=>pickyear(obj.target.value)} value={year}>
                                <option>Year</option>
                                <option>1990</option>
                                <option>1991</option>
                                <option>1992</option>
                                <option>1993</option>
                            </select>
                        </div>
                    </div>
                </div>
            
    </div>
    <div className='row m-1'>
        <div className='col-lg-3'>
            <label>Address</label>
            </div>
            <div className='col-lg-9'>
               <textarea className='form-control sm' onChange={obj=>pickAddress(obj.target.value)} value={address}></textarea>
            </div>
    </div>
    <div className='row m-1'>
        <div className='col-lg-3'>
            <label>Pincode</label>
            </div>
            <div className='col-lg-9'>
                <input type="text" className='form-control sm'onChange={obj=>pickPincode(obj.target.value)} value={pincode}/>
            </div>
    </div>
    <div className='row m-1'>
        <div className='col-lg-3'>
            <label>State</label>
            </div>
            <div className='col-lg-9'>
            <select className='form-select sm' onChange={obj=>pickState(obj.target.value)} value={state}>
            <option>Choose state</option>
            <option>Karnataka</option>
            <option>Kerala</option>
            <option>Delhi</option>
            <option>UP</option>
            <option>Andhra</option>
        </select>
            </div>
    </div>
    <div className='row m-1'>
        <div className='col-lg-3'>
            <label>District</label>
            </div>
            <div className='col-lg-9'>
            <select className='form-select sm' onChange={obj=>pickDistrict(obj.target.value)} value={district}>
            <option>Choose District</option>
            <option>Hassan</option>
            <option>Banglore</option>
            <option>Hubli</option>
            <option>Ballari</option>
            <option>chitradurga</option>
        </select>
            </div>
    </div>
    <div className='text-center mt-3'>
    <input type="checkbox" checked={tc} className="form-check-input"  onChange={obj=>picktc(obj.target.checked)}/>Agree Terms & Coditions


    </div>
    <div className='text-center mb-4 mt-3'>
            <button className='btn btn-primary m-1'>Register</button>
            <Link className='btn btn-primary m-1' onClick={clear}>Clear</Link>
        </div>
</form>
    </div>


    
 </div>
    
</div>

</>
    )
};
export default Register;