import React from 'react';
import { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';
const Chat=()=>{
    const options = [
        {
          text: 'Visit OpenAI website',
          link: 'https://openai.com',
          image: 'openai-logo.png',
          value: 'openai',
        },
        {
          text: 'Visit React website',
          link: 'https://reactjs.org',
          image: 'react-logo.png',
          value: 'react',
        },
        // Add more options as needed
      ];
    
      const handleOptionClick = (link) => {
        window.open(link, '_blank');
      };
      const theme = {
        background: '#fff',
        headerBgColor: '#197B22',
        headerFontSize: '20px',
        botBubbleColor: '#0F3789',
        headerFontColor: 'white',
        botFontColor: 'white',
        userBubbleColor: '#FF5733',
        userFontColor: 'white',
        
      
      };
      const config = {
        botAvatar: "coequallogo1.png",
        floating: true,
        height: '410px',
      };
    return(
        <>
       <div>
       <ThemeProvider theme={theme} >
        
        <ChatBot
          // This appears as the header
          // text for the chat bot

          headerTitle='Chat with Us!'
          height='100px'

          steps={options}
          {...config}

        />
       
      
      </ThemeProvider>
    
    </div>
        </>
    )
};
export default Chat;