// import React from 'react';
// import './counter.css';
// import $ from 'jquery';
// const Counter=()=>{
//     $(document).ready(function(){
//         $('.counter-value').each(function(){
//             $(this).prop('Counter',0).animate({
//                 Counter: $(this).text()
//             },{
//                 duration: 100,
//                 easing: 'swing',
//                 step: function (now){
//                     $(this).text(Math.ceil(now));
//                 }
//             });
//         });
//     });

    
//     return(
//         <>
      
//       <div class="container bg-light border rounded mb-5">
//       <div class="section-title text-center position-relative pb-3 mt-2 mx-auto" style={{ maxwidth: 600 }}>
//           <h2 className='text-danger'>OUR <span style={{ color: "purple" }}>ACHIEVE</span><span className='text-danger'>MENTS</span></h2>
//         </div>
//     <div class="row mb-3"data-aos="flip-left"
//      data-aos-easing="ease-out-cubic"
//      data-aos-duration="2000">
//         <div class="col-md-3 col-sm-6">
//             <div class="counter green ">
//                 <div class="counter-icon zoom">
//                     <i class="fa fa-users"></i>
//                 </div>
//                 <span class="counter-value">200</span>
//                 <h3>Members</h3>
//             </div>
//         </div>
//         <div class="col-md-3 col-sm-6">
//             <div class="counter blue">
//                 <div class="counter-icon">
//                     <i class="fas fa-coins"></i>
//                 </div>
//                 <span class="counter-value">50</span>
//                 <h3>Fixed Deposits</h3>
//             </div>
//         </div>
//         <div class="col-md-3 col-sm-6">
//             <div class="counter">
//                 <div class="counter-icon">
//                     <i class="fas fa-user-tie"></i>
//                 </div>
//                 <span class="counter-value">20</span>
//                 <h3>Experienced Staffs</h3>
//             </div>
//         </div>
//         <div class="col-md-3 col-sm-6">
//             <div class="counter purple">
//                 <div class="counter-icon">
//                     <i class="fas fa-sack-dollar"></i>
//                 </div>
//                 <span class="counter-value">20</span>
//                 <h3>Recurring Deposits</h3>
//             </div>
//         </div>
//     </div>
// </div>
//         </>
//     )
// };
// export default Counter;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import './counter.css';

const cards = [
	{
		count: "200",
		title: "Members",
	},
	{
		count: "50",
		title: "Fixed Deposits",
	},
	{
		count: "20",
		title: "Experienced Staffs",
	},
	{
		count: "20",
		title: "Recurring Deposits",
	},
];

const CardItem = ({ item }) => (
	<div className="mb-5 text-center">
		<h3 className="mb-3">{item.count}</h3>
		<h5>{item.title}</h5>
	</div>
);

CardItem.propTypes = {
	item: PropTypes.object.isRequired,
};

const Cards = () => {
	const divider = Math.ceil(cards.length / 2);

	return (
		<>
			<Col xs={12} md={6}>
				{cards.slice(0, divider).map((item, i) => (
					<CardItem item={item} key={i} />
				))}
			</Col>

			<Col xs={12} md={6}>
				{cards.slice(divider).map((item, i) => (
					<CardItem item={item} key={i} />
				))}
			</Col>
		</>
	);
};

const Numbers8 = () => {
	return (
		<section className="ezy__numbers8 ">
			<Container fluid data-aos="zoom-in-up" data-aos-duration="2000">
				{/* heading */}
				<Row className="align-items-center justify-content-between">
					<Col xs={12} lg={6} className="text-center text-lg-start">
						<h1 className="ezy__numbers8-heading">We believe in Quality & Equality</h1>

						<Row className="mt-5">
							<Cards />
						</Row>
					</Col>
					<Col xs={12} lg={5}>
						<img
							src="https://cdn.easyfrontend.com/pictures/blog/blog_6.jpg"
							alt=""
							className="img-fluid mt-5 mt-md-0"
						/>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Numbers8;